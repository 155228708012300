<template>
  <div>
    <v-overlay :value="loading" z-index=1000>
      <scale-loader :loading="loading" :color="'rgb(101,123,219)'" :size="'45px'"></scale-loader>
    </v-overlay>
    <nav-bar></nav-bar>
    <v-container>
      <v-row>
        <v-col>
          <v-alert v-if="alert"
            :type="typeAlert"
            transition="scale-transition"
          >{{alertInfo}}
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card class="mb-4">
            <v-toolbar class="mb-12" color="primary">
              <v-toolbar-title class="white--text mx-12">
                <v-card-title class="title_doc">Lista de usuarios</v-card-title>
              </v-toolbar-title>
              <slot>
                <v-btn class="mr-5" fab bottom right absolute @click="showNewUserDialog()">
                  <v-icon>mdi-account-plus</v-icon>
                </v-btn>
              </slot>
              <slot>
                <v-btn
                  v-if="!showUsers"
                  style="margin-left: 45%"
                  fab
                  bottom
                  absolute
                  @click="showElementsInToolbar('users')">
                  <v-icon>mdi-arrow-down-circle-outline</v-icon>
                </v-btn>
                <v-btn
                  v-if="showUsers"
                  style="margin-left: 45%;"
                  fab
                  bottom
                  absolute
                  @click="showElementsInToolbar('users')">
                  <v-icon>mdi-arrow-up-circle-outline</v-icon>
                </v-btn>
              </slot>
            </v-toolbar>
            <v-expand-transition>
              <v-card-text v-show="showUsers">
                <v-data-table dense :headers="userHeaders" :items="users" :search="userSearch" :loading="loadingUsers">
                  <template v-slot:top>
                    <v-text-field
                      class="mb-5"
                      v-model="userSearch"
                      append-icon="mdi-magnify"
                      label="Buscar"
                      single-line
                      hide-details>
                    </v-text-field>
                  </template>
                  <template v-slot:item.is_active="{ item }">
                    <v-checkbox v-model="item.is_active" disabled></v-checkbox>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-btn small class="ma-1" color="accent" outlined rounded @click="showUpdateUserDialog(item)">
                    Actualizar <v-icon dark>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                  small class="ma-2" color="error" v-if="false"
                  outlined rounded :disabled="true" @click="showDeleteUserDialog(item)">
                    Eliminar <v-icon dark>mdi-delete</v-icon>
                  </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card class="mb-4">
            <v-toolbar class="mb-12" color="primary">
              <v-toolbar-title class="white--text mx-12">
                <v-card-title class="title_doc">Lista de palabras</v-card-title>
              </v-toolbar-title>
              <slot>
                <v-btn class="mr-5" fab bottom right absolute @click="showNewWordDialog()">
                  <v-icon>mdi-translate</v-icon>
                </v-btn>
              </slot>
              <slot>
                <v-btn
                  v-if="!showWords"
                  style="margin-left: 45%"
                  fab
                  bottom
                  absolute
                  @click="showElementsInToolbar('words')">
                  <v-icon>mdi-arrow-down-circle-outline</v-icon>
                </v-btn>
                <v-btn
                  v-if="showWords"
                  style="margin-left: 45%;"
                  fab
                  bottom
                  absolute
                  @click="showElementsInToolbar('words')">
                  <v-icon>mdi-arrow-up-circle-outline</v-icon>
                </v-btn>
              </slot>
            </v-toolbar>
            <v-expand-transition>
              <v-card-text v-show="showWords">
              <v-data-table dense :headers="wordHeaders" :items="wordItemList" :loading="loadingWords">
                <template v-slot:top>
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12" md="9">
                        <v-text-field
                          class="mb-5"
                          v-model="wordSearch"
                          append-icon="mdi-magnify"
                          label="Buscar"
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-select
                          v-model="searchFilterOnlyActiveWords"
                          :items="searchFilterOnlyActiveOptions"
                          label="Solo Activos"
                          underlined
                          item-text="text"
                          item-value="value"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
                <template v-slot:item.is_active="{ item }">
                  <v-checkbox v-model="item.is_active" disabled></v-checkbox>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn small class="ma-1" color="accent" outlined rounded @click="showUpdateWordDialog(item)">
                  Actualizar <v-icon dark>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn small class="ma-2" color="error" outlined rounded @click="showDeleteWordDialog(item)">
                  Eliminar <v-icon dark>mdi-delete</v-icon>
                </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card class="mb-4">
            <v-toolbar class="mb-12" color="primary">
              <v-toolbar-title class="white--text mx-12">
                <v-card-title>Fusionar palabras</v-card-title>
              </v-toolbar-title>
              <!-- <slot>
                <v-btn class="mr-5" fab bottom right absolute @click="showNewWordDialog()">
                  <v-icon>mdi-translate</v-icon>
                </v-btn>
              </slot> -->
              <slot>
                <v-btn
                  v-if="!showMergeWords"
                  style="margin-left: 45%"
                  fab
                  bottom
                  absolute
                  @click="showElementsInToolbar('merge_words')">
                  <v-icon>mdi-arrow-down-circle-outline</v-icon>
                </v-btn>
                <v-btn
                  v-if="showMergeWords"
                  style="margin-left: 45%;"
                  fab
                  bottom
                  absolute
                  @click="showElementsInToolbar('merge_words')">
                  <v-icon>mdi-arrow-up-circle-outline</v-icon>
                </v-btn>
              </slot>
            </v-toolbar>
            <v-expand-transition>
              <v-card-text v-show="showMergeWords">
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" md="6" lg="4">
                      <v-text-field
                        class="mb-5"
                        v-model="mergeWords.from_word"
                        append-icon="mdi-export"
                        label="Palabra ID origen"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                      <v-text-field
                        class="mb-5"
                        v-model="mergeWords.to_word"
                        append-icon="mdi-import"
                        label="Palabra ID destino"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                      <v-btn class="mt-3" color="success" outlined rounded type="submit" @click="sendMergeWords()"
                      :disabled="mergeWordsFormValid">
                      Fusionar <v-icon dark>mdi-save</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <p>Anotaciones:</p>
                      <ul>
                        <li>
                          El campo es rellenado con la <strong>ID de la palabra</strong> que puede ser
                          encontrado en la tabla "<i>Lista de palabras</i>"
                        </li>
                        <li>
                          <strong>Origen</strong>: Palabra que será fusionada con destino y posteriormente
                          eliminada
                        </li>
                        <li>
                          <strong>Destino</strong>: Palabra predominante a la que será fusionada y mantendrá la
                          existencia
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                </v-container>
            </v-card-text>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- USER DIALOGS -->
    <v-dialog v-model="updateUserDialog" max-width="800" :retain-focus="false">
      <v-card class="dialog-style">
        <v-card-title>
          <span class="text-h5">Actualizar usuario</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field underlined v-model="userObject.email" label="Email*" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="userObject.first_name"
                  underlined
                  label="Nombre"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="userObject.last_name"
                  label="Apellido"
                  underlined
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="userObject.hand"
                  :items="constants.handOptions"
                  label="Mano"
                  underlined
                  item-text="text"
                  item-value="value"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="userObject.audition_state"
                  :items="constants.auditStates"
                  label="Audición"
                  item-text="text"
                  item-value="value"
                  underlined
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="userObject.gender"
                  :items="constants.genderOptions"
                  label="Género"
                  item-text="text"
                  item-value="value"
                  underlined
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="userObject.fototype_skin"
                  :items="constants.fototypeOptions"
                  label="Tonalidad de piel (fototipo)"
                  underlined
                  item-text="text"
                  item-value="value"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="userObject.signal_language_knowledge"
                  :items="constants.slKnowledgeOptions"
                  label="Conocimiento Lengua de Signo"
                  underlined
                  item-text="text"
                  item-value="value"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="userObject.is_active"
                  label="Usuario activo?"
                  required
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
          <small>*Campos requeridos</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded color="error" @click="updateUserDialog = false">Cancelar</v-btn>
          <v-btn outlined rounded color="success" @click="updateUser()">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteUserDialog" max-width="800" :retain-focus="false">
      <v-card class="dialog-style">
        <v-card-title class="text-h6 justify-center">
        ¿Está seguro de querer eliminar el usuario {{userObject.email}}?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded color="error" @click="deleteUserDialog = false">Cancelar</v-btn>
          <v-btn outlined rounded color="success" @click="deleteUser()">Sí</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newUserDialog" max-width="800" :retain-focus="false">
      <v-card class="dialog-style">
        <v-card-title>
          <span class="text-h5">Crear usuario</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="userObject.email"
                  label="Email*"
                  type="email"
                  required
                  underlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="userObject.first_name"
                  label="Nombre"
                  underlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="userObject.last_name"
                  label="Apellido"
                  underlined
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="userObject.password"
                  label="Contraseña*"
                  type="password"
                  underlined
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="userObject.hand"
                  :items="constants.handOptions"
                  label="Mano*"
                  underlined
                  item-text="text"
                  item-value="value"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="userObject.audition_state"
                  :items="constants.auditStates"
                  label="Audición*"
                  underlined
                  item-text="text"
                  item-value="value"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="userObject.gender"
                  :items="constants.genderOptions"
                  label="Género*"
                  underlined
                  item-text="text"
                  item-value="value"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="userObject.fototype_skin"
                  :items="constants.fototypeOptions"
                  label="Tonalidad de piel (fototipo)*"
                  underlined
                  item-text="text"
                  item-value="value"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="userObject.signal_language_knowledge"
                  :items="constants.slKnowledgeOptions"
                  label="Conocimiento Lengua de Signo*"
                  underlined
                  item-text="text"
                  item-value="value"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-checkbox
                  v-model="userObject.is_active"
                  label="Usuario activo?"
                  required
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
          <small>*Campos requeridos</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded color="error" @click="newUserDialog = false">Cancelar</v-btn>
          <v-btn outlined rounded color="success" :disabled="userCreatedFormValid" @click="createUser()">Crear</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- WORD DIALOGS -->
    <v-dialog v-model="updateWordDialog" max-width="800" :retain-focus="false">
      <v-card class="dialog-style">
        <v-card-title>
          <span class="text-h5">Actualizar palabra</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field underlined v-model="wordObject.word" label="Palabra*" required></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="wordObject.clarification" underlined label="Clarification"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="wordObject.video_help_reference"
                  label="Video referencia de ejemplo"
                  hint="Vídeo de referencia para esta palabra si existe en el bucket (grabado por algún usuario)
                  u<id>_w<id>_c<n>"
                  placeholder="Ejemplo: u10-w2-c1"
                  :rules="videoReferenceRule"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox v-model="wordObject.is_active" label="¿Palabra activa?" required></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
          <small>*Campos requeridos</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded color="error" @click="updateWordDialog = false">Cancelar</v-btn>
          <v-btn outlined rounded color="success" @click="updateWord()">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteWordDialog" max-width="800" :retain-focus="false">
      <v-card class="dialog-style">
        <v-card-title class="text-h6 justify-center">
        ¿Está seguro de querer eliminar la palabra?
        </v-card-title>
        <v-card-text>
          <p class="text-h4 text-center mt-2">{{wordObject.word}}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded color="error" @click="deleteWordDialog = false">Cancelar</v-btn>
          <v-btn outlined rounded color="success" @click="deleteWordAndVideos()">Palabra y vídeos</v-btn>
          <v-btn outlined rounded color="success" @click="deleteWordVideos()">Solo vídeos</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newWordDialog" max-width="800" :retain-focus="false">
      <v-card class="dialog-style">
        <v-card-title>
          <span class="text-h5">Crear palabra</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field underlined v-model="wordObject.word" label="Palabra*" required></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="wordObject.clarification" underlined label="Clarification"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="wordObject.video_help_reference"
                  label="Video referencia de ejemplo"
                  hint="Vídeo de referencia para esta palabra si existe en el bucket (grabado por algún usuario)
                  u<id>_w<id>_c<n>"
                  placeholder="Ejemplo: u10-w2-c1"
                  :rules="videoReferenceRule"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="wordObject.is_active"
                  label="¿Palabra activa?"
                  required
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
          <small>*Campos requeridos</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded color="error" @click="newWordDialog = false">Cancelar</v-btn>
          <v-btn outlined rounded color="success" :disabled="wordCreatedFormValid" @click="createWord()">Crear</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';
import NavBar from '@/components/NavBar.vue';
import { wordServices } from '@/services/wordServices';
import { userServices } from '@/services/userServices';
import { constants } from '@/utils/constants';

export default {
  name: 'ControlPanel',
  components: {
    NavBar,
    ScaleLoader,
  },
  computed: {
    wordItemList() {
      let items = this.words.filter((item) => this.tableFilterItemActive(item));
      if (this.wordSearch !== '') {
        items = items.filter(
          (item) => item.word.toString().toLowerCase().indexOf(this.wordSearch.toLowerCase()) !== -1,
        );
      }
      return items;
    },
    userCreatedFormValid() {
      return !(
        'email' in this.userObject
        && this.userObject.email !== ''
        && this.userObject.email !== null
        && 'first_name' in this.userObject
        && this.userObject.first_name !== ''
        && this.userObject.first_name !== null
        && 'last_name' in this.userObject
        && this.userObject.last_name !== ''
        && this.userObject.last_name !== null
      );
    },
    wordCreatedFormValid() {
      return !(
        'word' in this.wordObject
        && this.wordObject.word !== ''
        && this.wordObject.word !== null
      );
    },
    mergeWordsFormValid() {
      return !(
        'to_word' in this.mergeWords
        && this.mergeWords.to_word !== null
        && this.mergeWords.to_word !== ''
        && 'from_word' in this.mergeWords
        && this.mergeWords.from_word !== null
        && this.mergeWords.from_word !== ''
        && this.mergeWords.from_word !== this.mergeWords.to_word
      );
    },
  },
  data() {
    return {
      constants,
      loading: false,
      alert: false,
      typeAlert: 'error',
      alertInfo: '',
      showUsers: false,
      newUserDialog: false,
      updateUserDialog: false,
      deleteUserDialog: false,
      userObject: {},
      userSearch: '',
      showWords: false,
      newWordDialog: false,
      updateWordDialog: false,
      deleteWordDialog: false,
      wordObject: {},
      wordSearch: '',
      users: [],
      words: [],
      videoReferenceRule: [
        (value) => (!value
        || /^u([0-9]+)_w([0-9]+)_c([0-9]+)$/.test(value))
        || 'No cumple con la referencia buscada u<id>_w<id>_c<n>',
      ],
      userHeaders: [
        { text: 'ID', value: 'id' },
        { text: 'Email', value: 'email' },
        { text: 'Mano', value: 'hand' },
        { text: 'Sexo', value: 'gender' },
        { text: 'Audición', value: 'audition_state' },
        { text: 'Tonalidad', value: 'fototype_skin' },
        { text: 'Con.', value: 'signal_language_knowledge' },
        { text: 'Act.', value: 'is_active' },
        { text: 'Acciones', value: 'actions' },
      ],
      wordHeaders: [
        { text: 'ID', value: 'id' },
        { text: 'Palabra', value: 'word' },
        { text: 'Clarificación', value: 'clarification' },
        { text: 'Referencia de ayuda', value: 'video_help_reference' },
        { text: 'Act.', value: 'is_active' },
        { text: 'Acciones', value: 'actions' },
      ],
      searchFilterOnlyActiveWords: 0,
      searchFilterOnlyActiveOptions: [
        { text: 'Todos', value: 0 },
        { text: 'Sí', value: 1 },
        { text: 'No', value: 2 },
      ],
      showMergeWords: false,
      mergeWords: {},
    };
  },
  async created() {
    this.loadUsers();
    this.loadWords();
  },
  methods: {
    tableFilterItemActive(item) {
      /* if (this.searchFilterOnlyRecorded === 1) { // Yes
        return item.is_active;
      } */
      if (this.searchFilterOnlyActiveWords === 2) { // No
        return !item.is_active;
      } // All
      return true;
    },
    showAlert(typeAlert, msg) {
      this.typeAlert = typeAlert;
      this.alertInfo = msg;
      this.alert = true;
      setTimeout(() => {
        this.alert = false;
        this.alertInfo = '';
      }, 3000);
    },
    showElementsInToolbar(elementsToShow) {
      if (elementsToShow === 'users') {
        this.showUsers = !this.showUsers;
      } else if (elementsToShow === 'words') {
        this.showWords = !this.showWords;
      } else if (elementsToShow === 'merge_words') {
        this.showMergeWords = !this.showMergeWords;
      }
    },
    showNewUserDialog() {
      this.userObject = {};
      this.newUserDialog = true;
    },
    showNewWordDialog() {
      this.wordObject = {};
      this.newWordDialog = true;
    },
    /** Show update user dialog
     * @param {Object} user Object to update
     */
    showUpdateUserDialog(user) {
      this.userObject = { ...user };
      this.updateUserDialog = true;
    },
    /** Show update word dialog
     * @param {Object} word Object to update
     */
    showUpdateWordDialog(word) {
      this.wordObject = { ...word };
      this.updateWordDialog = true;
    },
    /** Show delete dialog.
     * @param {Object} user Selected user object
     */
    showDeleteUserDialog(user) {
      this.userObject = { ...user };
      this.deleteUserDialog = true;
    },
    /** Show delete dialog.
     * @param {Object} word Selected word object
     */
    showDeleteWordDialog(word) {
      this.wordObject = { ...word };
      this.deleteWordDialog = true;
    },
    /** Load all words */
    loadWords() {
      this.loadingWords = true;
      wordServices.getWordsManage().then((response) => {
        this.words = response.data;
        this.loadingWords = false;
      });
    },
    /** Load all words */
    loadUsers() {
      this.loadingUsers = true;
      userServices.getUsers().then((response) => {
        this.users = response.data;
        this.loadingUsers = false;
      });
    },
    updateUser() {
      this.loading = true;
      userServices.updateUser(this.userObject.id, this.userObject)
        .then((response) => {
          const foundIndex = this.users.findIndex((user) => user.id === this.userObject.id);
          this.users.splice(foundIndex, 1, { ...response.data });
          this.userObject = {};
          this.showAlert('success', response.data.first_name);
          this.updateUserDialog = false;
          this.loading = false;
        })
        .catch(() => {
          this.showAlert('error', 'Ha ocurrido un error actulizando el usuario');
          this.loading = false;
        });
    },
    createUser() {
      this.loading = true;
      userServices.createUser(this.userObject)
        .then((response) => {
          this.users.unshift(response.data);
          this.loading = false;
          this.newUserDialog = false;
          this.userObject = {};
          this.showAlert('success', 'Usuario creado con éxito');
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.showAlert('error', 'No se ha podido crear el usuario');
        });
    },
    deleteUser() {
      // this.loading = true;
      // userServices.deleteUser(this.userObject.id)
      //   .then(() => {
      //     this.showAlert('success', 'Usuario eliminado');
      //     this.deleteUserDialog = false;
      //     this.users = this.users.filter((user) => user.id !== this.userObject.id);
      //     this.userObject = {};
      //   })
      //   .catch(() => {
      //     this.showAlert('error', 'Usuario no puede ser eliminado');
      //   });
    },
    /** Update word in database */
    updateWord() {
      this.loading = true;
      wordServices.updateWord(this.wordObject.id, this.wordObject)
        .then((response) => {
          const foundIndex = this.words.findIndex((word) => word.id === this.wordObject.id);
          this.words.splice(foundIndex, 1, { ...response.data });
          this.loading = false;
          this.updateWordDialog = false;
          this.wordObject = {};
          this.showAlert('success', 'Palabra actualizada con éxito');
        })
        .catch((error) => {
          console.log(error.response.data.code);
          this.loading = false;
          if (error.response.data.code === 'video_help_reference') {
            this.showAlert('error', 'Referencia de vídeo de ayuda no válido');
          } else {
            this.showAlert('error', 'No se ha podido actualizar la palabra');
          }
        });
    },
    /** Create new word in database */
    createWord() {
      this.loading = true;
      wordServices.createWord(this.wordObject)
        .then((response) => {
          this.words.unshift(response.data);
          this.loading = false;
          this.newWordDialog = false;
          this.wordObject = {};
          this.showAlert('success', 'Palabra creada con éxito');
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.showAlert('error', 'No se ha podido crear la palabra');
        });
    },
    /** Delete word from database and videos from Bucket */
    deleteWordAndVideos() {
      this.loading = true;
      wordServices.deleteWordAndVideos(this.wordObject.id)
        .then(() => {
          this.loading = false;
          this.words = this.words.filter((item) => item.id !== this.wordObject.id);
          this.deleteWordDialog = false;
          this.wordObject = {};
          this.showAlert('success', 'Palabra y vídeos eliminados con éxito');
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.showAlert('error', 'No se ha podido eliminar la palabra y los vídeos');
        });
    },
    /** Delete only the videos from the Bucket for this word */
    deleteWordVideos() {
      this.loading = true;
      wordServices.deleteWordVideos(this.wordObject.id)
        .then(() => {
          this.loading = false;
          this.deleteWordDialog = false;
          this.wordObject = {};
          this.showAlert('success', 'Vídeos eliminados con éxito');
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.showAlert('error', 'No se ha podido eliminar los vídeos de la palabra');
        });
    },
    sendMergeWords() {
      this.loading = true;
      wordServices.mergeWords(this.mergeWords)
        .then(() => {
          this.loading = false;
          this.words = this.words.filter((item) => item.id !== this.mergeWords.from_word);
          this.mergeWords = {};
          this.showAlert('success', 'Fusión completada con éxito');
        })
        .catch(() => {
          this.loading = false;
          this.showAlert('error', 'No se ha podido fusionar la palabra');
        });
    },
  },
};
</script>
<style lang="scss">
table .v-input--checkbox {
  margin-top: 5px;
  .v-input__slot {
    margin-bottom: 0;
  }
}
</style>
