<template>
  <div>
    <div class="split left sslr-bg-gradient">
      <div class="centered">
          <img
            class="responsive-img"
            width="250"
            src="../assets/logosermes_large.png"
            alt="logo sermes">
      </div>
    </div>
    <div class="split right">
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md8>
            <div>
              <v-progress-circular
              v-if="!alert"
              class="centered" indeterminate color="primary"
              model-value="20" :size="128"></v-progress-circular>
              <v-alert
                v-if="alert"
                :type="typeAlert"
                transition="scale-transition">
                {{ alertInfo }}
              </v-alert>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>
<script>
import { userServices } from '@/services/userServices';

export default {
  name: 'ActivateAccount',
  components: {},
  data() {
    return {
      token: this.$route.params.token,
      alert: false,
      typeAlert: 'success',
      alertInfoOk: 'Activado correctamente. En unos momentos será redirigido para iniciar sesión.',
      alertInfoKo: 'Hubo un problema activando la cuenta. Prueba de nuevo en unos minutos.',
    };
  },
  methods: {
    activate() {
      userServices.activateAccount(this.token)
        .then(() => {
          this.typeAlert = 'success';
          this.alert = true;
          setTimeout(() => {
            this.$router.push('/login');
          }, 5000);
        }).catch(() => {
          this.typeAlert = 'error';
          this.alert = true;
        });
    },
  },
  computed: {
    alertInfo() {
      return this.typeAlert === 'success' ? this.alertInfoOk : this.alertInfoKo;
    },
  },
  mounted() {
    this.activate();
  },
};
</script>

<style scoped>
.split {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
}
.left {
  left: 0;
}
.right {
  right: 0;
  background-color:white;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
</style>
