import { coreRest } from '@/services/coreServices';

export const userServices = {
  async signup(data) {
    return coreRest.post('/accounts/register/', data);
  },
  async login(email, password) {
    const user = { email, password };
    return coreRest.post('/accounts/login/', user);
  },
  async getUserInfo() {
    return coreRest.get('/accounts/me/', coreRest.getRequestHeader());
  },
  async getMyRegistries() {
    return coreRest.get('/accounts/me/registries/', coreRest.getRequestHeader());
  },
  logout() {
    return coreRest.post('/accounts/logout/', {}, coreRest.getRequestHeader());
  },
  activateAccount(token) {
    return coreRest.post('/accounts/activate-account/', { token });
  },
  getMaxFeedbackVideoLength() {
    return coreRest.get('/accounts/feedback/', coreRest.getRequestHeader());
  },
  sendEmailFeedback(video, comment) {
    const formData = new FormData();
    formData.append('file', video);
    formData.append('comment', comment);
    const headers = coreRest.getRequestHeader();
    delete headers['Content-Type'];
    return coreRest.post('/accounts/feedback/', formData, headers);
  },
  changePassword(data) {
    return coreRest.post('/accounts/update-password/', data, coreRest.getRequestHeader());
  },
  resetPassword(data) {
    const header = coreRest.getRequestHeader();
    delete header.Authorization;
    return coreRest.post('/accounts/forgot-password/', data, header);
  },
  updatePassword(data) {
    const header = coreRest.getRequestHeader();
    delete header.Authorization;
    return coreRest.post('/accounts/forgot-password/confirm/', data, header);
  },
  updateExpiredPassword(data) {
    const header = coreRest.getRequestHeader();
    delete header.Authorization;
    return coreRest.post('/accounts/update-expired-password/', data, header);
  },
  getUsers() {
    return coreRest.get('/accounts/', coreRest.getRequestHeader());
  },
  /** Create a new user
   * @param {Object} data dictionary of the user
   */
  createUser(data) {
    return coreRest.post('/accounts/', data, coreRest.getRequestHeader());
  },
  /** Delete user
   * @param {int} userId User id to delete
   */
  deleteUser(userId) {
    return coreRest.delete(`/accounts/${userId}/`, coreRest.getRequestHeader());
  },
  /** Update a user
   * @param {Object} data dictionary of the user
   * @param {int} userId User id to update
   */
  updateUser(userId, data) {
    return coreRest.put(`/accounts/${userId}/`, data, coreRest.getRequestHeader());
  },
};
