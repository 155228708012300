import { coreRest } from '@/services/coreServices';

export const videoServices = {
  /** Upload a video to server */
  async uploadVideo(video, config) {
    const formData = new FormData();
    formData.append('file', video);
    formData.append('config', JSON.stringify(config));
    const headers = coreRest.getRequestHeader();
    headers['Content-Type'] = 'multipart/form-data';
    return coreRest.post('/language/videos/upload/', formData, headers);
  },
  /** Send a photo to check if the area to record is in good conditions */
  async checkArea(data) {
    const headers = coreRest.getRequestHeader();
    headers['Content-Type'] = 'multipart/form-data';
    return coreRest.post('/area-checker/check/', data, headers);
  },
};
