var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticClass:"appBar sslr-bg-gradient",attrs:{"dark":""}},[_c('v-toolbar-title',[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/"}},[_c('span',{staticClass:"appName text-center"},[_vm._v("HandsLator")])])],1),_c('v-spacer'),(_vm.sessionManager.isStaff())?_c('router-link',{attrs:{"to":"/dashboard"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"icons-style",attrs:{"large":""}},[_vm._v("mdi-view-dashboard")])],1)]}}],null,false,1792184722)},[_c('span',[_vm._v("Dashboard")])])],1):_vm._e(),(_vm.sessionManager.isStaff())?_c('router-link',{attrs:{"to":"/control-panel"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"icons-style",attrs:{"large":""}},[_vm._v("mdi-account-multiple-outline")])],1)]}}],null,false,4001174311)},[_c('span',[_vm._v("Panel de control")])])],1):_vm._e(),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/instructions"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"icons-style",attrs:{"large":""}},[_vm._v("mdi-information-outline")])],1)]}}])},[_c('span',[_vm._v("Instrucciones")])])],1),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/profile"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"icons-style",attrs:{"large":""}},[_vm._v("mdi-account-circle-outline")])],1)]}}])},[_c('span',[_vm._v("Perfil")])])],1),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/feedback"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"icons-style",attrs:{"large":""}},[_vm._v("mdi-send-circle-outline")])],1)]}}])},[_c('span',[_vm._v("Feedback")])])],1),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/login"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.logout()}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"icons-style",attrs:{"large":""}},[_vm._v("mdi-logout")])],1)]}}])},[_c('span',[_vm._v("Cerrar sesión")])])],1)],1),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }