export const Utils = {
  /**
   * Check if password has enough characteres
   * @param {String} pw Password to check
   */
  parseToUrlParams(dict) {
    const final = [];
    Object.keys(dict).forEach((key) => {
      if (dict[key] === null) final.push(`${key}=`);
      else final.push(`${key}=${dict[key]}`);
    });
    return final.join('&');
  },
};
