export const constants = {
  auditStates: [
    { text: 'Sordera pro-locutiva', value: 'Sordera pro-locutiva' },
    { text: 'Sordera pos-locutiva', value: 'Sordera pos-locutiva' },
    { text: 'Hipoacusia', value: 'hipoacusia' },
    { text: 'Oyente', value: 'Oyente' },
  ],
  handOptions: [
    { text: 'Zurdo', value: 'zurdo' },
    { text: 'Diestro', value: 'diestro' },
  ],
  slKnowledgeOptions: [
    { text: 'Traductor/profesional: Profundo conocimiento de LSE', value: 'academico' },
    {
      text: 'Excelente: Conocimiento de un gran número de palabras y muy buena fluidez en conversación',
      value: 'excelente',
    },
    { text: 'Medio: Conocimiento de numerosas palabras y cierta fluidez en conversación', value: 'medio' },
    { text: 'Principiante: Escaso o nulo conocimiento de LSE', value: 'principiante' },
  ],
  genderOptions: [
    { text: 'Hombre', value: 'hombre' },
    { text: 'Mujer', value: 'mujer' },
    { text: 'N/A', value: 'n/a' },
  ],
  fototypeOptions: [
    { text: 'Fototipo 1: piel muy clara', value: 'Fototipo 1' },
    { text: 'Fototipo 2: piel clara', value: 'Fototipo 2' },
    { text: 'Fototipo 3: piel clara intermedia', value: 'Fototipo 3' },
    { text: 'Fototipo 4: piel oliva', value: 'Fototipo 4' },
    { text: 'Fototipo 5: piel oscura', value: 'Fototipo 5' },
    { text: 'Fototipo 6: piel muy oscura', value: 'Fototipo 6' },
    { text: 'N/A', value: 'N/A' },
  ],
};
