import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#657bdb',
        secondary: '#53BE8C',
        accent: '#ffcc80',
        error: '#CC2020',
        success: '#53BE8C',
      },
    },
  },
});
