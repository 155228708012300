import { coreRest } from '@/services/coreServices';
import { Utils } from '@/utils/utils';

export const dashboardServices = {
  async getRecordedWordCount() {
    return coreRest.get('/dashboard/recorded-word-count/', coreRest.getRequestHeader());
  },
  async getUserRecordedCount() {
    return coreRest.get('/dashboard/user-recorded-count/', coreRest.getRequestHeader());
  },
  async getRecordedDateCount(data) {
    const final = Utils.parseToUrlParams(data);
    return coreRest.get(`/dashboard/recorded-date-count/?${final}`, coreRest.getRequestHeader());
  },
  async getRecordedTotalCount() {
    return coreRest.get('/dashboard/recorded-total-count/', coreRest.getRequestHeader());
  },
};
