<template>
  <div class="edit-div">
    <v-overlay :value="loading" z-index=1000>
      <scale-loader :loading="loading" :color="'rgb(101,123,219)'" :size="'45px'"></scale-loader>
    </v-overlay>
    <div>
      <v-alert v-if="alertSuccess"
        class="mt-4"
        type="success">
        Contraseña cambiada con éxito
      </v-alert>
      <v-alert v-if="alert"
        class="mt-4"
        type="error"
        transition="scale-transition">{{ error }}</v-alert>
      <v-expansion-panels id="change-password-expasion-panels" elevation="0" class="elevation-0">
        <v-expansion-panel id="change-password-expasion-panel" elevation="0" class="elevation-0">
          <v-expansion-panel-header>
            <strong>Cambiar contraseña</strong>
          </v-expansion-panel-header>
          <v-expansion-panel-content id="change-password-expasion-content">
            <v-row>
              <v-col>
                <v-text-field
                  underlined
                  v-model="oldPassword"
                  label="Antigua contraseña"
                  type="password"
                  placeholder="Contraseña"
                  required
                ></v-text-field>
                <v-text-field
                  underlined
                  v-model="newPassword"
                  label="Nueva contraseña"
                  type="password"
                  :rules="[passwordRules]"
                  placeholder="Password"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="repeatedPassword"
                  label="Repetir nueva contraseña"
                  type="password"
                  placeholder="Repetir nueva contraseña"
                  required
                ></v-text-field>
              </v-col>
              <v-col>
                <div>
                  <p>
                    <strong>Contraseña debe contener</strong>
                  </p>
                  <ul class="no-bullets">
                    <li>
                      <v-icon @change="colorLenRules" :color="colorLength">mdi-check-bold</v-icon> {{ lengthRule }}
                    </li>
                    <li>
                      <v-icon @change="colorUpperRules" :color="colorUpper">mdi-check-bold</v-icon>{{ upperRule }}
                    </li>
                    <li>
                      <v-icon @change="colorLowerRules" :color="colorLower">mdi-check-bold</v-icon>{{ lowerRule }}
                    </li>
                    <li>
                      <v-icon @change="colorNumRules" :color="colorNumber">mdi-check-bold</v-icon>{{ numberRule }}
                    </li>
                    <li>
                    <v-icon @change="colorCharRules" :color="colorCharacter">mdi-check-bold</v-icon>{{ characterRule }}
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center pt-2 pb-3">
                <v-btn
                  outlined
                  rounded
                  color="success"
                  @click="savePassword"
                  :disabled="formValid">
                  Cambiar mi contraseña
                </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import { PasswordChecker } from '@/utils/passwordUtilities';
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';
import { userServices } from '@/services/userServices';

export default {
  name: 'ChangePassword',
  props: {
    email: String,
  },
  components: {
    ScaleLoader,
  },
  data() {
    return {
      PasswordChecker,
      items: [
        { title: 'Home', icon: 'mdi-home-city' },
        { title: 'My Account', icon: 'mdi-account' },
        { title: 'Users', icon: 'mdi-account-group-outline' },
      ],
      newPassword: '',
      repeatedPassword: '',
      oldPassword: '',
      userId: '',
      error: '',
      alert: false,
      alertSuccess: false,
      loading: false,
      lengthRule: 'Al menos 8 caracteres.',
      upperRule: 'Al menos 1 letra mayúscula (A..Z)',
      lowerRule: 'Al menos 1 letra minúscula (a..z)',
      numberRule: 'Al menos 1 número (0...9)',
      characterRule: 'Al menos 1 caracter especial (/..-)',
      colorLength: 'grey',
      colorUpper: 'grey',
      colorLower: 'grey',
      colorNumber: 'grey',
      colorCharacter: 'grey',
    };
  },
  computed: {
    passwordRules() {
      return this.passwordStrong();
    },
    colorLenRules() {
      return this.passwordLen();
    },
    colorUpperRules() {
      return this.passwordUpper();
    },
    colorLowerRules() {
      return this.passwordLower();
    },
    colorNumRules() {
      return this.passwordNum();
    },
    colorCharRules() {
      return this.passwordChar();
    },
    formValid() {
      const validPassword = this.passwordStrong(this.newPassword);
      return !((validPassword)
            && (this.oldPassword !== this.newPassword)
            && (this.newPassword === this.repeatedPassword)
            && (this.oldPassword !== '' && this.newPassword !== ''
            && this.repeatedPassword !== ''));
    },
  },
  methods: {
    passwordStrong() {
      return PasswordChecker.passwordStrong(this.newPassword);
    },
    passwordLen() {
      const result = PasswordChecker.passwordLength(this.newPassword);
      this.colorLength = result ? 'green' : '';
      return result;
    },
    passwordUpper() {
      const result = PasswordChecker.passwordUpper(this.newPassword);
      this.colorUpper = result ? 'green' : '';
      return result;
    },
    passwordLower() {
      const result = PasswordChecker.passwordLower(this.newPassword);
      this.colorLower = result ? 'green' : '';
      return result;
    },
    passwordNum() {
      const result = PasswordChecker.passwordNum(this.newPassword);
      this.colorNumber = result ? 'green' : '';
      return result;
    },
    passwordChar() {
      const result = PasswordChecker.passwordChar(this.newPassword);
      this.colorCharacter = result ? 'green' : '';
      return result;
    },
    savePassword() {
      this.loading = true;
      const data = {
        old_password: this.oldPassword,
        new_password: this.newPassword,
      };
      userServices.changePassword(data)
        .then(() => {
          this.alertSuccess = true;
          this.oldPassword = '';
          this.newPassword = '';
          this.repeatedPassword = '';
          setTimeout(() => {
            this.alertSuccess = false;
          }, 1000);
          this.loading = false;
        })
        .catch(() => {
          this.alert = true;
          this.loading = false;
          this.error = 'Error al cambiar contraseña';
          setTimeout(() => {
            this.alert = false;
          }, 4000);
        });
    },
  },
};
</script>
<style scoped>
</style>
