<template>
  <div>
    <v-overlay :value="loading" z-index=1000>
      <scale-loader :loading="loading" :color="'rgb(101,123,219)'" :size="'45px'"></scale-loader>
    </v-overlay>
    <div class="split left sslr-bg-gradient">
      <div class="centered">
          <img
            class="responsive-img"
            width="250"
            src="../assets/logosermes_large.png"
            alt="logo sermes">
      </div>
    </div>
    <div class="split right">
      <v-alert v-if="error" type="error" transition="scale-transition">{{ errorInfo }}</v-alert>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md8>
            <v-form>
              <v-text-field
                underlined
                v-model="email"
                prepend-icon="mdi-account"
                name="email"
                label="Email"
                type="email"
              ></v-text-field>
              <v-text-field
                underlined
                v-model="password"
                id="password"
                prepend-icon="mdi-lock"
                name="password"
                label="Contraseña"
                type="password"
                @keyup.enter="login"
              ></v-text-field>
            </v-form>
            <v-spacer></v-spacer>
            <v-btn
              block
              outlined
              rounded
              large
              color="success"
              @click="login()"
              :disabled="formValid">
                Iniciar sesión
            </v-btn>
            <br/>
            <div class="text-center">
              ¿No tienes cuenta? <a href="/register"> Registrarse</a>
            </div>
            <br/>
            <div class="text-center">
              ¿Has olvidado la contraseña? <a href="/forgot-password"> Olvidé contraseña</a>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<script>
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';
import { sessionManager } from '@/utils/sessionManager';
import { userServices } from '@/services/userServices';

export default {
  name: 'LoginForm',
  components: {
    ScaleLoader,
  },
  data() {
    return {
      loading: false,
      email: '',
      password: '',
      error: false,
      errorInfo: 'Correo o contraseña no válidos',
    };
  },
  computed: {
    formValid() {
      return !(this.email !== '' && this.password !== '');
    },
  },
  methods: {
    login() {
      this.loading = true;
      userServices.login(this.email, this.password)
        .then((response) => {
          sessionManager.setUserLogged(
            response.data.access,
            response.data.refresh,
            response.data.user,
          );
          this.loading = false;
          this.$router.push('/instructions');
        }).catch((error) => {
          this.loading = false;
          const errorCode = error.response.data.code;
          if (errorCode === 'password_expired') {
            this.errorInfo = `La contraseña ha caducado, a continuación se le
              redirigirá a la página de cambio de contraseña`;
            this.error = true;
            setTimeout(() => {
              this.error = false;
              this.$router.push('/update-expired-password/');
            }, 5000);
          } else if (errorCode === 'no_active_account') {
            this.errorInfo = `Cuenta no activada, revisa la bandeja de entrada en
            caso de no haber activado anteriormente`;
            this.error = true;
            setTimeout(() => {
              this.error = false;
            }, 5000);
          } else {
            this.errorInfo = 'Email o contraseña incorrecta';
            this.error = true;
            setTimeout(() => {
              this.error = false;
            }, 5000);
          }
        });
    },
  },
};
</script>

<style scoped>
.split {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
}
.left {
  left: 0;
}
.right {
  right: 0;
  background-color:white;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
</style>
