<template>
  <div>
    <nav-bar></nav-bar>
    <v-container>
      <v-overlay :value="loading" z-index=1000>
        <scale-loader :loading="loading" :color="'rgb(101,123,219)'" :size="'45px'"></scale-loader>
      </v-overlay>
      <v-row>
        <v-col>
          <v-alert v-if="videoUploadedAlert"
            :type="videoUploadedAlertType"
            transition="scale-transition">
            {{videoUploadedAlertText}}
          </v-alert>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col sm="12" lg="5">
          <v-card v-show="!checkingArea">
            <v-card-title>Palabras</v-card-title>
            <v-divider></v-divider>
            <!-- List of words to select -->
            <v-card-subtitle class="pb-0" v-if="!wordSelected">Filtros</v-card-subtitle>
            <v-container fluid class="mb-0 pb-0" v-if="!wordSelected">
              <v-row>
                <v-col cols="6" class="mb-0 pb-3">Solo grabados</v-col>
                <v-col cols="6" class="mb-0 pb-0">
                  <v-select
                    class="pt-0 mt-0"
                    v-model="searchFilterOnlyRecorded"
                    :items="searchFilterOnlyRecordedOptions"
                    label="Solo grabados"
                    underlined
                    single-line
                    item-text="text"
                    item-value="value"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
            <v-card-text class="pt-1" v-if="!wordSelected">
              <v-data-table :headers="headers" :items="wordItemList" @click:row="tableRowClick"
              :loading="loadingWords" loading-text="Cargando palabras...">
                <template v-slot:top>
                  <v-text-field
                    class="mb-5"
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    ></v-text-field>
                </template>
                <template v-slot:item.recorded="{ item }" class="word-item">
                  <v-icon v-if="item.n > 0" color="green">mdi-check</v-icon>
                  <v-icon v-else color="red">mdi-close</v-icon>
                </template>
              </v-data-table>
            </v-card-text>
            <!-- Selected word info. -->
            <v-card-subtitle v-if="wordSelected">Selección</v-card-subtitle>
            <v-card-text  v-if="wordSelected">
              <p class="font-size-20">
                <strong>Palabra</strong>: {{ wordSelected.word }}
              </p>
              <record-video-doubt v-if="wordSelected.video_help_reference"
              :word="wordSelected.word" :word_id="wordSelected.id" :key="wordSelected.id">
              </record-video-doubt>
              <p class="font-size-20 mt-3"
                 v-if="wordSelected.clarification !== '' && wordSelected.clarification !== null">
                <strong>Aclaración</strong>: {{ wordSelected.clarification }}
              </p>
            </v-card-text>
            <v-card-actions v-if="wordSelected" class="justify-center">
              <div class="text-center">
                <v-btn outlined rounded color="error" @click="selectOtherWord()">seleccionar otra palabra</v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col sm="12" lg="7">
          <v-card class="pb-2" v-show="!photoTaken && !blobRecorded">
            <v-card-title>
              <v-icon>mdi-camera</v-icon> <span class="ml-2">Previsualización directo</span>
            </v-card-title>
            <v-divider></v-divider>
            <template>
              <div class="video-count-down">
                <video id="live"></video>
                <div id="clock">
                  <span id="countdown-msg"></span>
                  <br/>
                  <span id="seconds"></span>
                </div>
              </div>
              <div id="crop-container">
                <canvas ref="cropCanvas" :width="naturalWidth" :height="naturalHeight"></canvas>
              </div>
              <v-divider class="mx-3"></v-divider>
            </template>
            <v-card-actions class="justify-center">
              <v-btn v-if="liveStream && !checkingArea"
                class="mt-2"
                outlined
                rounded
                id="record"
                :disabled="recordDisable"
                color="success"
                @click="activeCountdown()">
                Grabar
              </v-btn>
              <v-btn v-if="!checkingArea"
                class="mt-2"
                outlined
                rounded
                id="area_checker"
                color="primary"
                @click="checkAreaCountdown()">
                Comprobar area de grabación
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card v-show="photoTaken">
            <v-card-title>
              <v-icon>mdi-camera</v-icon> <span class="ml-2">Previsualización foto de comprobación area</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="text-center">
              <canvas id="checking-area-canvas" width="640" height="480"></canvas>
              <img :src="photoTaken">
            </v-card-text>
            <v-card-text v-if="checkAreaResponse.length > 0">
              <p class="font-size-20">Sugerencias recibidas:</p>
              <ul>
                <li v-for="item in checkAreaResponse" :key="item">{{ item }}</li>
              </ul>
            </v-card-text>
            <v-card-actions class="justify-center">
              <!-- <v-btn
                class="mt-2"
                outlined
                rounded
                id="area_checker_send"
                color="primary"
                @click="checkAreaSend()">
                Enviar
              </v-btn> -->
              <v-btn
                class="mt-2"
                outlined
                rounded
                id="area_checker_cancel"
                color="error"
                @click="checkAreaCancel()">
                Volver
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card v-show="!photoTaken && blobRecorded">
            <v-card-title>
              <v-icon>mdi-video-check</v-icon> <span class="ml-2">Video grabado</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="text-center">
              <video id="recording" controls></video><br />
              <v-divider class="mx-3"></v-divider><br/>
              <div v-if="commentsEnabled" class="pl-2 pr-2">
                <v-divider class="mx-3"></v-divider>
                  <v-textarea
                    v-model="comment"
                    outlined
                    auto-grow
                    @keydown.enter.prevent="postReply"
                    name="input-7-4"
                    label="Comentario"
                    value=""
                  ></v-textarea>
              </div>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                v-if="videoRecorded"
                outlined
                rounded
                color="sucess"
                @click="submitVideoToCloudStorage(videoRecorded)"
                :disabled="recordDisable || disableSendBtn">
                Guardar
              </v-btn>
              <v-btn
                v-if="videoRecorded"
                outlined
                rounded
                color="error"
                @click="reRecord()"
                :disabled="recordDisable || disableSendBtn">
                Regrabar
              </v-btn>
              <v-btn
                v-if="videoRecorded && !commentsEnabled"
                outlined
                rounded
                color="accent"
                @click="enableComment()"
                :disabled="recordDisable || disableSendBtn || commentsEnabled">
                Añadir Comentario
              </v-btn>
              <v-btn v-if ="commentsEnabled"
                outlined
                rounded
                color="accent"
                @click="removeComment()"
                :disabled="recordDisable || disableSendBtn">
                Quitar comentario
              </v-btn>
              <br/>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import NavBar from '@/components/NavBar.vue';
import RecordVideoDoubt from '@/components/RecordVideoDoubt.vue';
import { videoServices } from '@/services/videoServices';
import { userServices } from '@/services/userServices';
import { wordServices } from '@/services/wordServices';

export default {
  name: 'Home',
  components: {
    NavBar,
    ScaleLoader,
    RecordVideoDoubt,
  },
  data() {
    return {
      loading: false,
      words: [],
      loadingWords: false,
      search: '',
      searchFilterOnlyRecorded: 0,
      searchFilterOnlyRecordedOptions: [
        { text: 'Todos', value: 0 },
        { text: 'Sí', value: 1 },
        { text: 'No', value: 2 },
      ],
      headers: [
        { text: 'Grabado', value: 'recorded', width: '107px' },
        { text: 'Palabra', value: 'word' },
        { text: 'Clarificación', value: 'clarification' },
        { text: 'Veces grabada', value: 'n', width: '50px' },
      ],
      recordButton: '',
      stopButton: '',
      recorder: '',
      liveStream: '',
      checkingArea: false,
      recordDisable: true,
      stopDisable: true,
      wordSelected: null,
      numberOfRecord: 1,
      videoRecorded: {},
      photoTaken: null,
      checkAreaResponse: [],
      disableSendBtn: true,
      naturalWidth: 350,
      naturalHeight: 290,
      countdownActive: false,
      countDownStartTime: 0,
      deadline: 0,
      timeLeft: 6,
      blobRecorded: false,
      videoUploadedAlert: false,
      videoUploadedAlertText: '',
      videoUploadedAlertType: '',
      commentsEnabled: false,
      comment: '',
    };
  },
  async created() {
    try {
      this.loadData();
    } catch (err) {
      console.log(err);
    }
    await this.loadWords();
  },
  mounted() {
    this.cropper = new Cropper(this.$refs.cropCanvas, {
      background: false,
      modal: true,
      highlight: false,
      cropBoxMovable: false,
      cropBoxResizable: false,
      dragMode: 'none',
      guides: false,
      checkCrossOrigin: false,
      center: false,
      data: {
        // width: 40,
        height: 400,
      },
    });
  },
  computed: {
    wordItemList() {
      let items = this.words.filter((item) => this.tableFilterItemRecordedOption(item));
      if (this.search !== '') {
        items = items.filter((item) => item.word.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1);
      }
      return items;
    },
  },
  methods: {
    tableFilterItemRecordedOption(item) {
      if (this.searchFilterOnlyRecorded === 1) { // Yes
        return item.n > 0;
      }
      if (this.searchFilterOnlyRecorded === 2) { // No
        return item.n === 0;
      } // All
      return true;
    },
    /** Get the user media service information */
    loadData() {
      navigator.mediaDevices.getUserMedia({ audio: false, video: true })
        .then((stream) => {
          this.liveStream = stream;
          const liveVideo = document.getElementById('live');
          liveVideo.srcObject = stream;
          liveVideo.play();
        });
    },
    /** Load all words */
    loadWords() {
      this.loadingWords = true;
      wordServices.getWords().then((response) => {
        this.words = response.data.map((item) => (
          {
            recorded: false,
            n: 0,
            ...item,
          }
        ));
        this.loadRegistries();
      });
    },
    loadRegistries() {
      userServices.getMyRegistries().then((response) => {
        for (let index = 0; index < response.data.length; index += 1) {
          const idx = this.words.map((e) => e.id).indexOf(response.data[index].word_id);
          this.words[idx].n = response.data[index].times_recorded;
        }
        this.loadingWords = false;
      });
    },
    selectOtherWord() {
      this.wordSelected = null;
      this.recordDisable = true;
      this.blobRecorded = false;
    },
    tableRowClick(item) {
      this.wordSelected = { ...item };
      this.recordDisable = false;
      this.blobRecorded = false;
    },
    activeCountdown() {
      this.countdownActive = true;
      this.recordDisable = true;
      this.timeLeft -= 1;
      document.getElementById('countdown-msg').innerHTML = String(this.wordSelected.word);
      document.getElementById('seconds').innerHTML = String(this.timeLeft);
      if (this.timeLeft >= 0) {
        setTimeout(this.activeCountdown, 1000);
      } else {
        this.countdownActive = false;
        document.getElementById('countdown-msg').innerHTML = '';
        document.getElementById('seconds').innerHTML = '';
        this.startRecording();
        this.timeLeft = 6;
      }
    },
    startRecording() {
      const options = {
        mimeType: 'video/webm;codecs=vp9',
        videoBitsPerSecond: 512000,
      };
      this.recorder = new MediaRecorder(this.liveStream, options);
      this.recorder.facingMode = 'user';
      this.recorder.frameRate = 30;
      this.recorder.minWidth = 640;
      this.recorder.minHeight = 480;
      this.recorder.start();
      setTimeout(() => {
        this.recordDisable = false;
        this.stopDisable = true;
        this.recorder.stop();
        this.blobRecorded = true;
      }, 4000);
      this.recorder.ondataavailable = (e) => {
        const recordingVideo = document.getElementById('recording');
        recordingVideo.src = URL.createObjectURL(e.data, { type: 'video/mp4' });
        recordingVideo.play();
        this.videoRecorded = e.data;
        this.disableSendBtn = false;
        this.timeLeft = 6;
      };
    },
    submitVideoToCloudStorage(video) {
      // eslint-disable-next-line
      const idx = this.words.map((e) => e.id).indexOf(this.wordSelected.id);
      this.words[idx].n += 1;
      const config = {
        word_id: this.wordSelected.id,
        comments: this.comment ? this.comment : '',
      };
      this.loading = true;
      videoServices.uploadVideo(video, config)
        .then(() => {
          this.loading = false;
          this.blobRecorded = false;
          this.videoUploadedAlert = true;
          this.videoUploadedAlertText = 'El vídeo ha sido guardado exitosamente';
          this.videoUploadedAlertType = 'success';
          setTimeout(() => {
            this.videoUploadedAlert = false;
            this.comment = '';
          }, 3000);
        })
        .catch(() => {
          this.loading = false;
          this.videoUploadedAlert = true;
          this.videoUploadedAlertText = 'Error, el vídeo no se ha podido guardar correctamente';
          this.videoUploadedAlertType = 'error';
          this.blobRecorded = true;
          setTimeout(() => {
            this.videoUploadedAlert = false;
          }, 3000);
        });
    },
    checkAreaCountdown() {
      this.checkingArea = true;
      this.countdownActive = true;
      this.recordDisable = true;
      this.timeLeft -= 1;
      document.getElementById('countdown-msg').innerHTML = 'Póngase en posición';
      document.getElementById('seconds').innerHTML = String(this.timeLeft);
      if (this.timeLeft >= 0) {
        setTimeout(this.checkAreaCountdown, 1000);
      } else {
        this.countdownActive = false;
        document.getElementById('countdown-msg').innerHTML = '';
        document.getElementById('seconds').innerHTML = '';
        this.checkAreaTakePhoto();
        this.timeLeft = 6;
      }
    },
    checkAreaTakePhoto() {
      this.photoTaken = '';
      const canvas = document.getElementById('checking-area-canvas');
      const context = canvas.getContext('2d');
      context.fillStyle = '#AAA';
      context.drawImage(document.getElementById('live'), 0, 0, canvas.width, canvas.height);
      this.photoTaken = canvas.toDataURL('image/png');
      this.checkAreaSend();
    },
    checkAreaSend() {
      this.loading = true;
      this.checkAreaResponse = [];
      const splitDataURI = this.photoTaken.split(',');
      const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
      const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
      }
      const bl = new Blob([ia], { type: mimeString });
      const fd = new FormData();
      fd.append('image', bl, 'image');
      videoServices.checkArea(fd)
        .then((response) => {
          this.loading = false;
          if (response.data.messages.length > 0) {
            this.checkAreaResponse = response.data.messages;
          } else {
            this.checkAreaResponse = ['El area de grabación es ideal.'];
          }
        })
        .catch(() => {
          this.loading = false;
          this.videoUploadedAlert = true;
          this.videoUploadedAlertText = 'Error, el area o su posición no es correcto';
          this.videoUploadedAlertType = 'error';
          this.blobRecorded = true;
          setTimeout(() => {
            this.videoUploadedAlert = false;
          }, 3000);
        });
    },
    checkAreaCancel() {
      this.photoTaken = '';
      this.checkAreaResponse = [];
      this.checkingArea = false;
      const canvas = document.getElementById('checking-area-canvas');
      const context = canvas.getContext('2d');
      context.fillRect(0, 0, canvas.width, canvas.height);
    },
    reRecord() {
      this.disableSendBtn = true;
      this.recordDisable = false;
      this.blobRecorded = false;
      this.commentsEnabled = false;
      this.comment = null;
    },
    /** Activate comment box */
    enableComment() {
      this.commentsEnabled = true;
    },
    /** Deactivate comment box */
    removeComment() {
      this.commentsEnabled = false;
      this.comment = null;
    },
  },
};
</script>
<style scoped lang="scss">
#checking-area-canvas {
  display: none;
}
#records {
  margin-left: auto;
  font-size: 150%;
}
#live {
  // margin-left: 50px;
  border-radius: 8px;
  height: auto;
  display: table;
  margin-left: auto;
  margin-right: auto;
}
#recording {
  border-radius: 15px;
  height: auto;
  margin-left: 50px;
}
#crop-container {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  height: 450px;
  width: 600px;
  overflow: hidden;
}
.cropper-crop-box {
  width: 595px !important;
  transform: translateX(3px) !important;
}
.overlay {
  position: absolute;
  top: 80px;
  left: 150px;
  width: 100%;
  font-size: 0.52em;
  color: #fff;
  z-index: 1;
}
.overlay-text {
  font-size: 3.8em;
}
.v-application--wrap{
  background-color:white;
  color:lightgray;
}
.video-count-down {
  margin-top: 20px;
  margin-bottom: 20px;
  min-height: 460px;
  div {
    width: 100%;
    height: 0px;
    border-radius: 50%;
    background-color: transparent;
    margin: auto;
    z-index: 1;
  }
  .video {
    position: absolute;
    z-index: 0;
  }
}
#countdown-msg {
  text-align: center;
  font-size: 60px;
  position: absolute;
  display: block;
  width: 100%;
  z-index: 1;
  color: white;
  text-align: center;
  margin-top: -450px;
  // margin-left: -190px;
}
#seconds {
  text-align: center;
  font-size: 200px;
  position: absolute;
  display: block;
  width: 100%;
  z-index: 1;
  color: white;
  text-align: center;
  margin-top: -400px;
  // margin-left: -190px;
}
.cropper-modal {
  opacity: 0.3 !important;
}
#list-23 {
  margin-top: 30px;
}
#word {
  font-size: 150%;
}
.v-data-table.v-data-table--has-bottom > .v-data-footer > .v-data-footer__pagination {
  margin: 0 10px 0 10px !important;
}
</style>
