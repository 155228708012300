<template>
  <div>
    <nav-bar></nav-bar>
    <v-container>
      <v-row>
        <v-col>
          <v-card class="mt-5">
            <v-card-title>
              <v-avatar color="primary">
                <span class="white--text text-h5">
                  {{ user.email.substring(0, 2) }}
                </span>
              </v-avatar>
              <strong class="user-email">{{ user.email }}</strong>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <router-link :to="'/'">
                    <v-icon large color="primary" v-on="on">
                      mdi-arrow-left-circle
                    </v-icon>
                  </router-link>
                  <router-view/>
                </template>
                <span>Atrás</span>
              </v-tooltip>
              <!-- <v-avatar color="#9fbac9">
                <span class="white--text text-h5">
                  {{ user.initials }}
                </span>
              </v-avatar> -->
            </v-card-title>
            <v-card-text>
              <p>
                <strong>Mano dominante</strong>: {{ user.hand }}
              </p>
              <p>
                <strong>Género</strong>: {{ user.gender }}
              </p>
              <p>
                <strong> Fototipo de la piel</strong>: {{ user.fototype_skin }}
              </p>
              <p>
                <strong>Nivel de Lengua de signos</strong>: {{ user.signal_language_knowledge }}
              </p>
              <p>
                <strong>Audición</strong>: {{ user.audition_state }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card class="mt-5">
            <v-card-text>
              <change-password/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card class="mt-5">
            <v-card-title>
              Palabras Grabadas
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscador"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-card-text>
              <v-data-table :headers="headers" :items="words" :search="search"
              :loading="loadingRegistries" loading-text="Cargando registros..."></v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import NavBar from './NavBar.vue';
import { userServices } from '../services/userServices';
import ChangePassword from './ChangePassword.vue';

export default {
  name: 'UserProfile',
  components: {
    NavBar,
    ChangePassword,
  },
  data() {
    return {
      user: {
        id: 0,
        email: '',
        first_name: '',
        last_name: '',
      },
      search: '',
      words: [],
      loadingRegistries: false,
      headers: [
        { text: 'Palabra', value: 'word_name', width: '50%' },
        { text: 'Número de veces grabada', value: 'times_recorded' },
      ],
    };
  },
  async created() {
    await this.loadUserData();
    await this.loadRegistries();
  },
  methods: {
    loadRegistries() {
      this.loadingRegistries = true;
      userServices.getMyRegistries().then((response) => {
        this.words = response.data;
        this.loadingRegistries = false;
      });
    },
    loadUserData() {
      userServices.getUserInfo()
        .then((response) => { this.user = response.data; })
        .catch(() => {});
    },
  },
};

</script>

<style scoped>
.v-main--wrap {
 background-color: white !important;
}

.user-email {
  margin-left: 10px;
  font-size: 1.2em;
}
</style>
