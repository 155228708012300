import Vue from 'vue';
import VueRouter from 'vue-router';
import UpdateExpiredPasswordView from '@/views/UpdateExpiredPasswordView.vue';
import ActivateAccount from '@/views/ActivateAccount.vue';
import ControlPanel from '@/views/ControlPanel.vue';
import Feedback from '@/views/Feedback.vue';
import Dashboard from '@/views/Dashboard.vue';
import Home from '../views/HomeView.vue';
import Login from '../views/LoginView.vue';
import Register from '../views/RegisterView.vue';
import ForgotPassword from '../views/ForgotPasswordView.vue';
import Instructions from '../views/InstructionsView.vue';
import Profile from '../views/ProfileView.vue';
import ResetPassword from '../views/ResetPasswordView.vue';
import { cookieAsObject } from '../utils/cookieToObject';

Vue.use(VueRouter);
function requireCookie(to, from, next) {
  const cookieObject = cookieAsObject(document.cookie);
  const token = cookieObject.access_token;
  if (token == null) {
    next({ name: 'Login' });
  } else {
    next();
  }
}
const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: requireCookie,
  },
  {
    path: '/control-panel',
    name: 'ControlPanel',
    component: ControlPanel,
    beforeEnter: requireCookie,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: requireCookie,
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: Feedback,
    beforeEnter: requireCookie,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/update-expired-password/',
    name: 'UpdateExpiredPassword',
    component: UpdateExpiredPasswordView,
  },
  {
    path: '/activate-account/:token',
    name: 'ActivateAccount',
    component: ActivateAccount,
  },
  {
    path: '/instructions',
    name: 'Instructions',
    component: Instructions,
    beforeEnter: requireCookie,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    beforeEnter: requireCookie,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
