import Cookies from 'js-cookie';
import { getCookieExpiration } from '@/utils/cookieExpiration';

export const sessionManager = {
  setUserLogged(accessToken, refreshToken, user) {
    // add to Cooki userId
    // Cookies.set('access_token', accessToken);
    // Cookies.set('refresh_token', refreshToken);
    document.cookie = `user_id=${user.id}; expires=${getCookieExpiration().toUTCString()}`;
    document.cookie = `user_email=${user.email}; expires=${getCookieExpiration().toUTCString()}`;
    document.cookie = `user_is_staff=${user.is_staff}; expires=${getCookieExpiration().toUTCString()}`;
    document.cookie = `access_token=${accessToken}; expires=${getCookieExpiration().toUTCString()}`;
    document.cookie = `refresh_token=${refreshToken}; expires=${getCookieExpiration().toUTCString()}`;
    // Cookies.set('userHand', userLogged.user_hand);
    // Cookies.set('userAuditState', userLogged.user_audition);
    // Cookies.set('usergender', userLogged.user_gender);
    // Cookies.set('user_fototype_skin', userLogged.user_fototype_skin);
    // Cookies.set('userSignalLanguageKnowledge',userLogged.user_signal_language_knowledge);
  },
  logout() {
    document.cookie.split(';').forEach((c) => {
      document.cookie = `${c.trim().split('=')[0]}=; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
    });
    localStorage.clear();
  },
  isStaff() {
    return Cookies.get('user_is_staff') === 'true';
  },
};
