<template>
  <div>
    <div class="split left sslr-bg-gradient">
      <div class="centered">
        <img
          class="responsive-img"
          src="../assets/logosermes_large.png"
          style="max-height: 400px; max-width: 100%;"
          alt="logo sermes">
      </div>
    </div>
      <div class="split right">
        <v-alert v-if="error"
          type="error"
          transition="scale-transition">
          Correo no válido
        </v-alert>
        <v-container fluid fill-height>
          <v-layout align-center justify-center>
            <v-flex xs12 sm8 md8>
              <v-form>
                <v-text-field
                  underlined
                  v-model="email"
                  prepend-icon="mdi-account"
                  name="email"
                  label="Email"
                  type="email"></v-text-field>
                <v-text-field
                  id="change-password-newpass-field"
                  v-model="newPassword"
                  label="Nueva contraseña"
                  type="password"
                  prepend-icon="mdi-lock"
                  :rules="[passwordRules]"
                  placeholder="Nueva contraseña"
                  underlined
                  required></v-text-field>
                <v-text-field
                  id="change-password-repeatpass-field"
                  v-model="repeatedPassword"
                  label="Repetir nueva contraseña"
                  type="password"
                  :rules="[passwordConfirmationRule]"
                  prepend-icon="mdi-lock"
                  placeholder="Repetir nueva contraseña"
                  underlined
                  required></v-text-field>
              </v-form>
              <div>
                <p>
                  <strong>Contraseña debe contener</strong>
                </p>
                <ul class="no-bullets">
                  <li>
                    <v-icon @change="colorLenRules" :color="colorLength">mdi-check-bold</v-icon> {{ lengthRule }}
                  </li>
                  <li>
                    <v-icon @change="colorUpperRules" :color="colorUpper">mdi-check-bold</v-icon>{{ upperRule }}
                  </li>
                  <li>
                    <v-icon @change="colorLowerRules" :color="colorLower">mdi-check-bold</v-icon>{{ lowerRule }}
                  </li>
                  <li>
                    <v-icon @change="colorNumRules" :color="colorNumber">mdi-check-bold</v-icon>{{ numberRule }}
                  </li>
                  <li>
                  <v-icon @change="colorCharRules" :color="colorCharacter">mdi-check-bold</v-icon>{{ characterRule }}
                  </li>
                </ul>
              </div>
              <v-spacer></v-spacer>
              <div class="text-center mt-5" v-if="!success">
                <v-btn
                  class="mt-5"
                  outlined
                  rounded
                  @click="saveNewPassword"
                  :disabled="formValid">
                  Guardar nueva contraseña
                </v-btn>
              </div>
              <v-alert v-if="success"
                class="mt-5"
                type="success"
                transition="scale-transition">
                Restablecida con éxito
              </v-alert>
            </v-flex>
          </v-layout>
        </v-container>
    </div>
  </div>
</template>

<script>
import { PasswordChecker } from '@/utils/passwordUtilities';
import { userServices } from '@/services/userServices';

export default {
  name: 'ResetPassword',
  data() {
    return {
      PasswordChecker,
      email: '',
      userToken: this.$route.params.token,
      newPassword: '',
      repeatedPassword: '',
      error: '',
      success: false,
      lengthRule: 'Al menos 8 caracteres.',
      upperRule: 'Al menos 1 letra mayúscula (A..Z)',
      lowerRule: 'Al menos 1 letra minúscula (a..z)',
      numberRule: 'Al menos 1 número (0...9)',
      characterRule: 'Al menos 1 caracter especial (/..-)',
      colorLength: 'grey',
      colorUpper: 'grey',
      colorLower: 'grey',
      colorNumber: 'grey',
      colorCharacter: 'grey',
    };
  },
  computed: {
    passwordConfirmationRule() {
      return () => (this.newPassword === this.repeatedPassword) || 'Contraseña debe coincidir';
    },
    passwordRules() {
      return this.passwordStrong();
    },
    colorLenRules() {
      return this.passwordLen();
    },
    colorUpperRules() {
      return this.passwordUpper();
    },
    colorLowerRules() {
      return this.passwordLower();
    },
    colorNumRules() {
      return this.passwordNum();
    },
    colorCharRules() {
      return this.passwordChar();
    },
    formValid() {
      const validPassword = this.passwordStrong();
      return !((validPassword)
            && (this.newPassword === this.repeatedPassword)
            && (this.newPassword !== '' && this.repeatedPassword !== '')
            && this.email !== '');
    },
  },
  methods: {
    passwordStrong() {
      return PasswordChecker.passwordStrong(this.newPassword);
    },
    passwordLen() {
      const result = PasswordChecker.passwordLength(this.newPassword);
      this.colorLength = result ? 'green' : '';
      return result;
    },
    passwordUpper() {
      const result = PasswordChecker.passwordUpper(this.newPassword);
      this.colorUpper = result ? 'green' : '';
      return result;
    },
    passwordLower() {
      const result = PasswordChecker.passwordLower(this.newPassword);
      this.colorLower = result ? 'green' : '';
      return result;
    },
    passwordNum() {
      const result = PasswordChecker.passwordNum(this.newPassword);
      this.colorNumber = result ? 'green' : '';
      return result;
    },
    passwordChar() {
      const result = PasswordChecker.passwordChar(this.newPassword);
      this.colorCharacter = result ? 'green' : '';
      return result;
    },
    saveNewPassword() {
      const data = {
        email: this.email,
        token: this.userToken,
        new_password: this.newPassword,
      };
      userServices.updatePassword(data)
        .then(() => {
          this.success = true;
          setTimeout(() => {
            this.success = false;
            this.$router.push('/login');
          }, 5000);
        })
        .catch(() => {
          this.error = true;
          setTimeout(() => {
            this.error = false;
          }, 5000);
        });
    },
  },
};
</script>

<style scoped>
.split {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
}
.left {
  left: 0;
}
.right {
  right: 0;
  background-color:white;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
</style>
