<template>
  <div class="text-center">
    <v-dialog id="cookie-modal-dialog" v-model="dialog" width="500" persistent v-if="!isCookieAccepted()">
      <v-card id="cookie-modal-card">
        <v-card-title id="text-cookie-card-title" class="text-h5 sslr-bg-primary white-text">
          Uso de cookies
        </v-card-title>
        <v-card-text id="text-cookie-card-text" class="pt-4 cookies-text">
          Esta website usa cookies propias. Aceptas el tratamiento de cookies
          para el funcionamiento y mejora de la aplicación.
          Si estás de acuerdo con el uso de cookies, pulsa aceptar.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="accept-cookie-btn"
            outlined
            color="success"
            rounded
            class="accept-btn"
            @click="acceptCookie()">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import { getCookieExpiration } from '@/utils/cookieExpiration';

export default {
  name: 'Cookies',
  data() {
    return {
      dialog: true,
    };
  },
  methods: {
    isCookieAccepted() {
      const isAccepted = Cookies.get('AcceptedCookies');
      return isAccepted !== undefined && isAccepted !== '0' && isAccepted !== 0;
    },
    acceptCookie() {
      document.cookie = `AcceptedCookies=1; expires=${getCookieExpiration().toUTCString()}`;
      this.dialog = false;
    },
    getCookiesUrl() {
      // const environment = environmentService.getEnvironment();
      // if (environment === 'DEVELOP') {
      //   return '??';
      // }
      return '??';
    },
  },
};
</script>
