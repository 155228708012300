import { coreRest } from '@/services/coreServices';
import { Utils } from '@/utils/utils';

export const wordServices = {
  /** Get list of available words to record */
  async getWords() {
    return coreRest.get('/language/words/', coreRest.getRequestHeader());
  },
  /** Get all list of words for management of them. Only staff users */
  async getWordsManage() {
    return coreRest.get('/language/words/manage/', coreRest.getRequestHeader());
  },
  /** Create a new word
   * @param {Object} data dictionary of the word
   */
  createWord(data) {
    return coreRest.post('/language/words/', data, coreRest.getRequestHeader());
  },
  /** Update a word
   * @param {Object} data dictionary of the word
   * @param {int} userId Word id to update
   */
  updateWord(wordId, data) {
    return coreRest.put(`/language/words/${wordId}/`, data, coreRest.getRequestHeader());
  },
  /** Delete word and videos
   * @param {int} wordId Word id to delete
   */
  deleteWordAndVideos(wordId) {
    return coreRest.delete(`/language/words/${wordId}/`, coreRest.getRequestHeader());
  },
  /** Delete only videos from word
   * @param {int} wordId Word id to delete
   */
  deleteWordVideos(wordId) {
    return coreRest.delete(`/language/words/${wordId}/videos/`, coreRest.getRequestHeader());
  },
  /** Merge two words
   * @param {Object} data dictionary of the words: from to
   */
  mergeWords(data) {
    return coreRest.post('/language/words/merge/', data, coreRest.getRequestHeader());
  },
  getUserWordRegistries(page, pageSize) {
    const params = {};
    if (page) params.page = page;
    if (pageSize) params.page_size = pageSize;
    const final = Utils.parseToUrlParams(params);
    return coreRest.get(`/language/registries/?${final}`, coreRest.getRequestHeader());
  },
  getWordHelpVideo(wordId) {
    return coreRest.get(`/language/words/${wordId}/help-video/`, coreRest.getRequestHeader());
  },
};
