<template>
  <div class="sslr-bg-gradient wrapper-carousel">
    <v-carousel hide-delimiters :continuous=false class="intro-carousel" height="auto">
      <v-carousel-item v-for="(item, index) in items" :key="index">
        <v-container>
          <v-row>
            <v-col>
              <div v-if="index === 0">
                <h1 class="text-center mb-6">Bienvenido</h1>
                <p>
                  A continuación, se detallan las instruccion de cómo usar esta
                  interfaz, si desea saltarse este paso, haga click en saltar.
                  <br/>
                  Para continuar con las instrucciones, haga click en la flecha
                  de la derecha
                </p>
                <div class="text-center">
                  <video width="460" height="345" controls autoplay muted>
                    <source
                    :src="require('../assets/data_iso_explicaciones_intro_lse.mp4')" type="video/mp4">
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
              <div v-if="index === 1">
                <h2>
                  En la esquina superior derecha de la pantalla, se encuentran estos iconos.
                </h2>
                <div class="text-center">
                  <img
                    :src="require('../assets/images/instrucciones/Iconos-navbar.png')"
                    alt="instrucciones-1"
                    class="img-fluid"/>
                </div>
                <p>
                  Botón <v-icon large>mdi-information-outline</v-icon>
                  <br>
                  Visualizar las instrucciones de la aplicación. Las instrucciones <br/>
                  son estas pantallas que estás visualizando actualmente,siembre disponible de nuevo
                </p>
                <p>
                  Botón <v-icon large>mdi-account-circle-outline</v-icon>
                  <br>
                  Visualizar el perfil de usuario. En esta vista se mostrará información del usuario y
                  <br/>todas las palabras que ha grabado.
                </p>
                <p>
                  Botón <v-icon large>mdi-send-circle-outline </v-icon>
                  <br>
                  Enviar un correo a soporte técnico en caso que se haya
                  encontrado<br/> un error o una posible mejora en el uso de la herramienta.
                </p>
                <p>
                  Botón <v-icon large>mdi-logout</v-icon>
                  <br>
                  Salir de la aplicación
                </p>
              </div>
              <div v-if="index === 2">
                <p>
                  A la izquierda tenemos la lista de palabras para grabar. Es un menú desplegable
                  para ver todas las palabras que hay. Para comenzar con las grabaciones, hay que
                  seleccionar una palabra.
                </p>
                <div class="text-center">
                  <img
                  :src="require('../assets/images/instrucciones/instrucciones-2.png')"
                  style="height: 200px; !important"
                  alt="instrucciones-2"
                  class="img-fluid"/>
                </div>
                <p>
                  En cada línea del menú desplegable, hay 3 elementos:
                  <ul>
                    <li>Icono indicativo de si la palabra está grabada al menos una vez</li>
                    <li>La palabra a seleccionar para realizar la grabación.</li>
                    <li>Y finalmente, el número de veces que se ha grabado esa palabra.</li>
                  </ul>
                </p>
              </div>
              <div v-if="index === 3">
                <p>
                  La parte central hay una previsualización de la cámara
                  y es la parte de grabación. Dentro de este área hay
                  que colocarse correctamente para grabar con las siguientes condiciones:
                </p>
                <ul>
                  <li>Situado en la parte central de la grabación (en la línea del botón de grabar)</li>
                  <li>A una distancia que salga desde la cabeza hasta las manos con brazos extendidos hacia abajo.</li>
                  <li>Idealmente de pie</li>
                  <li>La iluminación debe ser buena</li>
                  <li>Fondo uniforme, liso y claro</li>
                  <li>No debe aparecer más que una persona ni otros objetos</li>
                  <li>(opcional) Usar ropa oscura</li>
                </ul>
              </div>
              <div v-if="index === 4">
                <p>
                  Seleccionar una palabra de la lista de palabras y pulsar el botón de grabar rojo.
                  Comenzará una cuenta atrás de 5 segundos
                  durante la cual hay que situarse en la zona de grabación cumpliendo los requisitos del paso anterior.
                  Al acabar la cuenta atrás, se inicia la grabación del signo.
                </p>
                <p>
                  La grabación del video se comenzará en una posición cómoda: brazos extendidos o cruzados en el
                  abdomen. Tras acabar el signo (seguramente sobrará tiempo extra),
                  se volverá a la posición cómoda original esperando la finalización del vídeo sin moverse.
                </p>
                <p>
                  La grabación dura unos segundos y al terminar, se muestra una previsualización del video.
                  Si es correcta, se pulsa guardar y si no es correcta, se pulsa descartar.
                </p>
                <p>
                  Al guardar el vídeo, se muestra el mensaje de todo correcto
                  y se puede seguir grabando otras palabras.
                </p>
              </div>
              <div v-if="index > 4">
                <p class="text-center">{{ item }}</p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-carousel-item>
    </v-carousel>
    <br/>
    <img :src="require('../assets/logosermes_large.png')"
      alt="instrucciones-1"
      class="img-fluid sermes-logo-large"/>
    <br/>
    <div class="text-center">
      <router-link to="/">
        <v-btn outlined rounded class="white--text" large>Saltar</v-btn>
      </router-link>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      items: [
        'Bienvenoido',
        'Instrucciones navbar',
        'Instrucciones palabras',
        'Situación de cámara',
        'Grabar un vídeo de la palabra',
        'Elija de la lista de palabras pendientes qué palabra quiere grabar',
        'Sitúese en la posición de grabación en la imagen (cabeza y manos dentro de zona sombreada)',
        'Pulse el botón de comienzo de grabación de la seña elegida',
        'Tras la cuenta atrás de 10 segundos, realice el signo elegido, durante un tiempo máximo de 3 segundos',
        'Acabada la grabación, puede reproducirla para aceptarla (subir vídeo) o rechazarla',
      ],
    };
  },
  created() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.v-btn--icon.v-size--default {
    width: 55px !important;
    height: 55px !important;
}
.sermes-logo-large {
    left: 40%;
    width: 20%;
    position: relative;
}
.v-window-item {
    min-height: 400px;
}
.wrapper-carousel {
  height: 100%;
  color: white;
  padding-top: 40px;
  padding-bottom: 40px;
  .intro-carousel {
  // text-align: center;
    * {
      color: white;
    }
    p {
      font-size: 24px;
    }
    ul {
      text-align: left;
      font-size: 22px;
    }
  }
}
</style>
