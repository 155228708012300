<template>
  <div>
    <v-app-bar dark class="appBar sslr-bg-gradient">
      <v-toolbar-title>
        <!-- <img
          class="mr-3 logo"
          src="../assets/logosermes_large.png"
          alt="logo sermes"
          height="40"
        /> -->
        <router-link to="/" style="text-decoration: none;">
          <span class="appName text-center">HandsLator</span>
        </router-link>
      </v-toolbar-title>
      <!-- <router-link to="/" style="margin-left: 30%; text-decoration: none;">
        <span class="appName text-center">HandsLator</span>
      </router-link> -->
      <v-spacer></v-spacer>
      <router-link to="/dashboard" v-if="sessionManager.isStaff()">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon large class="icons-style">mdi-view-dashboard</v-icon>
            </v-btn>
          </template>
          <span>Dashboard</span>
        </v-tooltip>
      </router-link>
      <router-link to="/control-panel" v-if="sessionManager.isStaff()">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon large class="icons-style">mdi-account-multiple-outline</v-icon>
            </v-btn>
          </template>
          <span>Panel de control</span>
        </v-tooltip>
      </router-link>
      <router-link to="/instructions" style="text-decoration: none;">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon large class="icons-style">mdi-information-outline</v-icon>
            </v-btn>
          </template>
          <span>Instrucciones</span>
        </v-tooltip>
      </router-link>
      <router-link to="/profile" style="text-decoration: none;">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon large class="icons-style">mdi-account-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>Perfil</span>
        </v-tooltip>
      </router-link>
      <router-link to="/feedback" style="text-decoration: none;">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon large class="icons-style">mdi-send-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>Feedback</span>
        </v-tooltip>
      </router-link>
      <router-link to="/login" style="text-decoration: none;">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="logout()">
              <v-icon large class="icons-style">mdi-logout</v-icon>
            </v-btn>
          </template>
          <span>Cerrar sesión</span>
        </v-tooltip>
      </router-link>
    </v-app-bar>
    <router-view></router-view>
  </div>
</template>

<script>
import { sessionManager } from '@/utils/sessionManager';

export default {
  name: 'NavBar',
  data() {
    return {
      sessionManager,
      dialogFeedback: false,
      files: null,
      items: [
        { title: 'Perfil', route: '/profile' },
        { title: 'Cerrar sesion', route: '/logout' },
      ],
    };
  },
  created() {},
  methods: {
    logout() {
      sessionManager.logout();
      this.$router.push('/login');
    },
  },
};
</script>

<style scoped>
.logo {
  height: 50px;
  margin-top:25px;
}
.appName {
  font-size: 1.5em;
  color: #fff;
}
.upload-input-feedback{
  margin: 0;
  padding: 0;
}
.v-main .appBar i.v-icon {
  color: white;
}
</style>
