<template>
  <div>
    <nav-bar></nav-bar>
    <v-container>
      <v-overlay :value="loading" z-index=1000>
        <scale-loader :loading="loading" :color="'rgb(101,123,219)'" :size="'45px'"></scale-loader>
      </v-overlay>
      <v-row>
        <v-col>
          <v-alert v-if="videoUploadedAlert"
            :type="videoUploadedAlertType"
            transition="scale-transition">
            {{videoUploadedAlertText}}
          </v-alert>
        </v-col>
      </v-row>
      <v-row >
        <v-col>
          <v-card>
            <v-card-title>
              <v-icon>mdi-message</v-icon> <span class="ml-2">Feedback</span>
            </v-card-title>
            <v-card-text>
              <v-textarea v-model="comment" outlined auto-grow label="Comentario"></v-textarea>
            </v-card-text>
            <v-card-text class="pb-0" v-show="!blobRecorded && !recordDisable">
              <h2><v-icon>mdi-camera</v-icon> <span class="ml-2">Previsualización directo</span></h2>
              <v-divider></v-divider>
              <div id="video-recorder-wrapper">
                <div class="video-count-down">
                  <video id="live"></video>
                  <div id="clock">
                    <span id="countdown-msg"></span>
                    <br/>
                    <span id="seconds"></span>
                  </div>
                </div>
                <div id="crop-container">
                  <canvas ref="cropCanvas" :width="naturalWidth" :height="naturalHeight"></canvas>
                </div>
              </div>
            </v-card-text>
            <v-card-text v-show="!blobRecorded && !recordDisable">
              <div class="recording-countdown-timer text-center">
                <span class="recording-countdown-timer-span">Tiempo restante: {{ videoRecordingTimeLeft }}</span>s
              </div>
            </v-card-text>
            <v-card-actions class="justify-center" v-show="!blobRecorded && !recordDisable">
              <v-btn class="mb-3" v-if="!isRecording"
                outlined
                rounded
                id="record"
                color="primary"
                @click="activeCountdown()">
                Grabar
              </v-btn>
              <v-btn class="mb-3" v-if="isRecording"
                outlined
                rounded
                id="record"
                color="primary"
                @click="stopRecording()">
                Parar grabación
              </v-btn>
            </v-card-actions>
            <v-card-text class="pb-0" v-show="blobRecorded">
              <h2><v-icon>mdi-video-check</v-icon> <span class="ml-2">Video grabado</span></h2>
              <v-divider></v-divider>
              <video id="recording" controls></video>
            </v-card-text>
            <v-card-actions class="justify-center" v-show="blobRecorded">
              <v-btn
                class="mb-3"
                v-if="videoRecorded"
                outlined
                rounded
                color="error"
                @click="reRecord()">
                Regrabar
              </v-btn>
            </v-card-actions>
            <v-divider class="mx-3"></v-divider>
            <v-card-actions class="justify-center">
              <v-btn v-if="liveStream"
                outlined
                rounded
                :color="recordDisable ? 'primary' : 'error'"
                @click="toggleRecordBox()">
                {{ recordDisable ? '¿Quieres grabar un vídeo?' : 'Cancelar vídeo' }}
              </v-btn>
              <v-btn
                outlined
                rounded
                color="success"
                @click="submitVideoToCloudStorage()"
                :disabled="disableSendBtn">
                Enviar feedback
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import { userServices } from '@/services/userServices';

export default {
  name: 'Feedback',
  components: {
    ScaleLoader,
    NavBar,
  },
  data() {
    return {
      loading: false,
      naturalWidth: 350,
      naturalHeight: 290,
      videoUploadedAlert: false,
      videoUploadedAlertText: '',
      videoUploadedAlertType: '',
      recorder: '',
      liveStream: '',
      recordDisable: true,
      isRecording: false,
      timeLeft: 6,
      videoRecordingMaxTime: 0,
      videoRecordingTimeLeft: 0,
      videoRecordingTimer: null,
      videoRecordingCountdownTimer: null,
      blobRecorded: false,
      comment: '',
      videoRecorded: null,
    };
  },
  async created() {
    try {
      this.loadMediaDevice();
    } catch (err) {
      console.log(err);
    }
    this.getMaxFeedbackVideoLength();
  },
  mounted() {},
  computed: {
    disableSendBtn() {
      return !(this.comment || this.videoRecorded !== null);
    },
  },
  methods: {
    /** Get the user media service information */
    loadMediaDevice() {
      navigator.mediaDevices.getUserMedia({ audio: false, video: true })
        .then((stream) => {
          this.liveStream = stream;
          const liveVideo = document.getElementById('live');
          liveVideo.srcObject = stream;
          liveVideo.play();
        });
    },
    toggleRecordBox() {
      this.recordDisable = !this.recordDisable;
      this.videoRecorded = null;
      if (!this.recordDisable) {
        this.createVideoMargins();
      }
      if (this.recordDisable) {
        this.stopRecording();
        this.blobRecorded = false;
        this.videoRecorded = null;
      }
    },
    createVideoMargins() {
      this.cropper = new Cropper(this.$refs.cropCanvas, {
        background: false,
        modal: true,
        highlight: false,
        cropBoxMovable: false,
        cropBoxResizable: false,
        dragMode: 'none',
        guides: false,
        checkCrossOrigin: false,
        center: false,
        data: {
          // width: 40,
          height: 400,
        },
      });
    },
    activeCountdown() {
      this.timeLeft -= 1;
      document.getElementById('countdown-msg').innerHTML = String('Prepárate');
      document.getElementById('seconds').innerHTML = String(this.timeLeft);
      if (this.timeLeft >= 0) {
        setTimeout(this.activeCountdown, 1000);
      } else {
        document.getElementById('countdown-msg').innerHTML = '';
        document.getElementById('seconds').innerHTML = '';
        this.startRecording();
        this.timeLeft = 6;
      }
    },
    startRecording() {
      this.isRecording = true;
      const options = {
        mimeType: 'video/webm;codecs=vp9',
        videoBitsPerSecond: 512000,
      };
      this.recorder = new MediaRecorder(this.liveStream, options);
      this.recorder.facingMode = 'user';
      this.recorder.frameRate = 30;
      this.recorder.minWidth = 640;
      this.recorder.minHeight = 480;
      this.recorder.start();
      this.startRecordingCountdownTimer();
      this.videoRecordingTimer = setTimeout(() => {
        this.stopRecording();
      }, this.videoRecordingMaxTime * 1000);
      this.recorder.ondataavailable = (e) => {
        const recordingVideo = document.getElementById('recording');
        recordingVideo.src = URL.createObjectURL(e.data, { type: 'video/mp4' });
        recordingVideo.play();
        this.videoRecorded = e.data;
      };
    },
    stopRecording() {
      this.recorder.stop();
      this.isRecording = false;
      this.blobRecorded = true;
      this.videoRecordingTimeLeft = this.videoRecordingMaxTime;
      clearTimeout(this.videoRecordingTimer);
      clearTimeout(this.videoRecordingCountdownTimer);
    },
    startRecordingCountdownTimer() {
      this.videoRecordingTimeLeft -= 1;
      if (this.videoRecordingTimeLeft >= 0) {
        this.videoRecordingCountdownTimer = setTimeout(this.startRecordingCountdownTimer, 1000);
      }
    },
    getMaxFeedbackVideoLength() {
      userServices.getMaxFeedbackVideoLength()
        .then((response) => {
          this.videoRecordingMaxTime = response.data.max_feedback_video_length;
          this.videoRecordingTimeLeft = response.data.max_feedback_video_length;
        })
        .catch(() => {
          this.videoRecordingMaxTime = 30;
          this.videoRecordingTimeLeft = 30;
        });
    },
    submitVideoToCloudStorage() {
      // eslint-disable-next-line
      this.loading = true;
      userServices.sendEmailFeedback(this.videoRecorded, this.comment ? this.comment : '(Vídeo)')
        .then(() => {
          this.loading = false;
          this.blobRecorded = false;
          this.videoUploadedAlert = true;
          this.videoUploadedAlertText = 'El vídeo ha sido guardado exitosamente';
          this.videoUploadedAlertType = 'success';
          this.videoRecorded = null;
          this.recordDisable = true;
          setTimeout(() => {
            this.videoUploadedAlert = false;
            this.comment = '';
          }, 3000);
        })
        .catch(() => {
          this.loading = false;
          this.videoUploadedAlert = true;
          this.videoUploadedAlertText = 'Error, el vídeo no se ha podido guardar correctamente';
          this.videoUploadedAlertType = 'error';
          this.blobRecorded = true;
          setTimeout(() => {
            this.videoUploadedAlert = false;
          }, 3000);
        });
    },
    reRecord() {
      this.blobRecorded = false;
      this.comment = null;
    },
  },
};
</script>

<style scoped lang="scss">
/* Recorded video */
#recording {
  border-radius: 15px;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  display: table;
  margin-left: auto;
  margin-right: auto;
}
/* Video recorder main box */
#video-recorder-wrapper {
  position: relative;
  #crop-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 450px;
    width: 600px;
    overflow: hidden;
  }
  #live {
    // margin-left: 50px;
    border-radius: 8px;
    height: auto;
    display: table;
    margin-left: auto;
    margin-right: auto;
  }
  .video-count-down {
    margin-top: 20px;
    margin-bottom: 20px;
    min-height: 460px;
    #clock {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9;
      padding-top: 50px;
      #countdown-msg {
        text-align: center;
        font-size: 60px;
        line-height: 60px;
        display: block;
        width: 100%;
        z-index: 1;
        color: white;
        text-align: center;
      }
      #seconds {
        text-align: center;
        font-size: 200px;
        display: block;
        width: 100%;
        z-index: 1;
        color: white;
        text-align: center;
        line-height: 200px;
      }
    }
    .video {
      position: absolute;
      z-index: 0;
    }
  }
}
.recording-countdown-timer {
  font-size: 50px;
  margin-bottom: 20px;
}
// .cropper-crop-box {
//   width: 595px !important;
//   transform: translateX(3px) !important;
// }
// .overlay {
//   position: absolute;
//   top: 80px;
//   left: 150px;
//   width: 100%;
//   font-size: 0.52em;
//   color: #fff;
//   z-index: 1;
// }
// .v-application--wrap {
//   background-color:white;
//   color:lightgray;
// }
</style>
