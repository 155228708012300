<template>
  <div>
    <nav-bar></nav-bar>
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <div v-if="loadingRecordedTotalCount" class="text-center">
                <v-progress-circular indeterminate color="primary"
                model-value="20" :size="60"></v-progress-circular>
              </div>
              <p v-else class="number-of-total-recordings text-center">
                Número de grabaciones totales: <span>{{ totalVideosFormatted }}</span>
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xs="12" md="6" >
          <v-card>
            <v-card-title>
              <span>Palabras más grabadas</span>
            </v-card-title>
            <v-card-text>
              <div v-if="loadingRecordedWordCount" class="text-center">
                <v-progress-circular indeterminate color="primary"
                model-value="20" :size="60"></v-progress-circular>
              </div>
              <div id="chartWordCount"></div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" md="6">
          <v-card>
            <v-card-title>
              <span>Usuarios más activos</span>
            </v-card-title>
            <v-card-text>
              <div v-if="loadingUserRecordedCount" class="text-center">
                <v-progress-circular indeterminate color="primary"
                model-value="20" :size="60"></v-progress-circular>
              </div>
              <div id="chartUserCount"></div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <span>Actividad</span>
            </v-card-title>
            <v-card-text>
              <div v-if="loadingRecordedDateCount" class="text-center">
                <v-progress-circular indeterminate color="primary"
                model-value="20" :size="60"></v-progress-circular>
              </div>
              <div id="chartDateCount"></div>
              <v-spacer></v-spacer>
              <div class="text-center">
                <v-container fluid>
                  <v-row>
                    <v-col offset-sm="2" cols="12" sm="3">
                      <v-menu
                        v-model="recordedDateCountFilters.menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="recordedDateCountFilters.from_recorded_date"
                            label="Desde"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            @click:clear="recordedDateCountFilters.from_recorded_date = null"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="recordedDateCountFilters.from_recorded_date"
                          @input="recordedDateCountFilters.menu1 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-menu
                        v-model="recordedDateCountFilters.menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="recordedDateCountFilters.to_recorded_date"
                            label="Hasta"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            @click:clear="recordedDateCountFilters.to_recorded_date = null"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="recordedDateCountFilters.to_recorded_date"
                          @input="recordedDateCountFilters.menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-btn block outlined rounded color="success" class="mt-2" @click="getRecordedDateCount()">
                        Filtrar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="mb-4">
            <v-toolbar class="mb-12" color="primary">
              <v-toolbar-title class="white--text mx-12">
                <v-card-title class="title_doc">Historial</v-card-title>
              </v-toolbar-title>
              <!-- <slot>
                <v-btn class="mr-5" fab bottom right absolute @click="showNewUserDialog()">
                  <v-icon>mdi-weather-cloudy-clock</v-icon>
                </v-btn>
              </slot> -->
              <slot>
                <v-btn
                  v-if="!showUserWordRegistry"
                  style="margin-left: 45%"
                  fab
                  bottom
                  absolute
                  @click="showUserWordRegistry = !showUserWordRegistry">
                  <v-icon>mdi-arrow-down-circle-outline</v-icon>
                </v-btn>
                <v-btn
                  v-if="showUserWordRegistry"
                  style="margin-left: 45%;"
                  fab
                  bottom
                  absolute
                  @click="showUserWordRegistry = !showUserWordRegistry">
                  <v-icon>mdi-arrow-up-circle-outline</v-icon>
                </v-btn>
              </slot>
            </v-toolbar>
            <v-expand-transition>
              <v-card-text v-show="showUserWordRegistry">
                <v-data-table dense
                :headers="userWordRegistryHeaders"
                :items="userWordRegistryResponse.results"
                :search="userWordRegistrySearch"
                :loading="loadingUserWordRegistry"
                :options.sync="userWordRegistryTable.options"
                :server-items-length="userWordRegistryResponse.count"
                :footer-props="{'items-per-page-options': [10, 20, 30, 40, 50, 100]}"
                :items-per-page="10">
                  <!-- <template v-slot:top>
                    <v-text-field
                      class="mb-5"
                      v-model="userWordRegistrySearch"
                      append-icon="mdi-magnify"
                      label="Buscar"
                      single-line
                      hide-details>
                    </v-text-field>
                  </template> -->
                  <template v-slot:item.creation_timestamp="{ item }">
                    {{ item.creation_timestamp.replace('T', ' ') }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ApexCharts from 'apexcharts';
import NavBar from '@/components/NavBar.vue';
import { wordServices } from '@/services/wordServices';
import { dashboardServices } from '../services/dashboardServices';

export default {
  name: 'Dashboard',
  components: {
    NavBar,
  },
  data() {
    return {
      // Chart: Absolut total count
      loadingRecordedTotalCount: false,
      recordedTotalCount: 0,
      // Chart: chartWordCount
      loadingRecordedWordCount: false,
      recordedWordCountData: {
        chart: { type: 'bar', width: '100%' },
        series: [{ name: 'Grabado', data: [] }],
        xaxis: { categories: [] },
        responsive: [{
          breakpoint: undefined,
          options: {},
        }],
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true,
          },
        },
        legend: { show: false },
        dataLabels: {
          textAnchor: 'start',
          style: { colors: ['#000'] },
        },
      },
      // Chart: chartUserCount
      loadingUserRecordedCount: false,
      userRecordedCountData: {
        chart: { type: 'bar', width: '100%' },
        series: [{ name: 'Grabado', data: [] }],
        xaxis: { categories: [] },
        responsive: [{
          breakpoint: undefined,
          options: {},
        }],
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true,
          },
        },
        legend: { show: false },
        dataLabels: {
          textAnchor: 'start',
          style: { colors: ['#000'] },
        },
      },
      userWordRegistryHeaders: [
        { text: 'ID', value: 'id', sortable: false },
        { text: 'Usuario', value: 'user', sortable: false },
        { text: 'Palabra', value: 'word.word', sortable: false },
        { text: 'Palabra clarificación', value: 'word.clarification', sortable: false },
        { text: 'Comentario', value: 'comment', sortable: false },
        { text: 'Timestamp', value: 'creation_timestamp', sortable: false },
      ],
      userWordRegistryTable: {
        options: {},
      },
      userWordRegistryResponse: {
        count: 1,
        pages_count: 1,
        current: 1,
        next: null,
        next_page: null,
        previous: null,
        previous_page: null,
        results: [],
      },
      userWordRegistrySearch: '',
      // Chart: chartDateCount
      loadingRecordedDateCount: false,
      recordedDateCountFilters: {
        menu1: false,
        from_recorded_date: null,
        menu2: false,
        to_recorded_date: null,
      },
      recordedDateCountChart: null,
      recordedDateCountData: {
        chart: { type: 'line', width: '100%', height: 400 },
        title: { text: '387', style: { fontSize: '26px' } },
        series: [{ name: 'Grabado', data: [] }],
        xaxis: { categories: [] },
        responsive: [{ breakpoint: undefined, options: {} }],
      },
      // Table: History
      showUserWordRegistry: true,
      loadingUserWordRegistry: false,
    };
  },
  watch: {
    'userWordRegistryTable.options': {
      handler() {
        this.getUserWordRegistries();
      },
      deep: true,
    },
  },
  async created() {
    this.getRecordedWordCount();
    this.getUserRecordedCount();
    // Init from to dates from line graph
    const today = new Date();
    const [fromDate] = new Date(today.getTime() - (14 * 60 * 1000 * 1000)).toISOString().split('T');
    const [todayDate] = today.toISOString().split('T');
    this.recordedDateCountFilters.from_recorded_date = fromDate;
    this.recordedDateCountFilters.to_recorded_date = todayDate;
    this.getRecordedDateCount();
    this.getUserWordRegistries();
    this.getRecordedTotalCount();
  },
  mounted() {
  },
  computed: {
    totalVideosFormatted() {
      let tostr = `${this.recordedTotalCount}`;
      tostr = tostr.split('').reverse().join('');
      tostr = tostr.match(/.{1,3}/g) || [];
      tostr = tostr.join('.');
      return tostr.split('').reverse().join('');
    },
  },
  methods: {
    getRecordedWordCount() {
      this.loadingRecordedWordCount = true;
      dashboardServices.getRecordedWordCount()
        .then((response) => {
          const data = [];
          const categories = [];
          response.data.forEach((item) => {
            data.push(item.recorded_count);
            categories.push(`#${item.word_id} ${item.word_name}`);
          });
          this.recordedWordCountData.series[0].data = data;
          this.recordedWordCountData.xaxis.categories = categories;
          const chart = new ApexCharts(document.querySelector('#chartWordCount'), this.recordedWordCountData);
          chart.render();
          this.loadingRecordedWordCount = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingRecordedWordCount = false;
        });
    },
    getUserRecordedCount() {
      this.loadingUserRecordedCount = true;
      dashboardServices.getUserRecordedCount()
        .then((response) => {
          const data = [];
          const categories = [];
          response.data.forEach((item) => {
            data.push(item.recorded_count);
            categories.push(`${item.email}`);
          });
          this.userRecordedCountData.series[0].data = data;
          this.userRecordedCountData.xaxis.categories = categories;
          const chart = new ApexCharts(document.querySelector('#chartUserCount'), this.userRecordedCountData);
          chart.render();
          this.loadingUserRecordedCount = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingUserRecordedCount = false;
        });
    },
    getRecordedDateCount() {
      this.loadingRecordedDateCount = true;
      const params = {
        from_date: this.recordedDateCountFilters.from_recorded_date,
        to_date: this.recordedDateCountFilters.to_recorded_date,
      };
      // if (this.recordedDateCountChart) this.recordedDateCountChart.destroy();
      dashboardServices.getRecordedDateCount(params)
        .then((response) => {
          const data = [];
          const categories = [];
          let totalCount = 0;
          response.data.forEach((item) => {
            data.push(item.recorded_count);
            totalCount += item.recorded_count;
            categories.push(`${item.datestamp}`);
          });
          this.recordedDateCountData.title.text = totalCount;
          this.recordedDateCountData.series[0].data = data;
          this.recordedDateCountData.xaxis.categories = categories;
          if (this.recordedDateCountChart) {
            this.recordedDateCountChart.updateOptions(this.recordedDateCountData);
          } else {
            this.recordedDateCountChart = new ApexCharts(
              document.querySelector('#chartDateCount'), this.recordedDateCountData,
            );
            this.recordedDateCountChart.render();
          }
          this.loadingRecordedDateCount = false;
        })
        .catch((error) => {
          this.recordedDateCountData = error;
          this.loadingRecordedDateCount = false;
        });
    },
    getUserWordRegistries() {
      const {
        page, itemsPerPage,
      } = this.userWordRegistryTable.options;
      this.userWordRegistryResponse.results = [];
      this.loadingUserWordRegistry = true;
      wordServices.getUserWordRegistries(page, itemsPerPage)
        .then((response) => {
          this.loadingUserWordRegistry = false;
          this.userWordRegistryResponse = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.loadingUserWordRegistry = false;
        });
    },
    getRecordedTotalCount() {
      this.loadingRecordedTotalCount = true;
      dashboardServices.getRecordedTotalCount()
        .then((response) => {
          this.loadingRecordedTotalCount = false;
          this.recordedTotalCount = response.data.total;
        })
        .catch((error) => {
          console.log(error);
          this.loadingRecordedTotalCount = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
#chartDateCount,
#chartUserCount,
#chartWordCount {
  max-height: 200px;
  margin: 35px auto;
}
.number-of-total-recordings {
  margin-top: 20px;
  font-size: 2em;
  font-weight: 400;
  span {
    font-weight: bold;
    font-size: 1.25em;
  }
}
</style>
