<template>
  <div>
    <cookies />
    <login-form />
  </div>
</template>
<script>
import Cookies from '@/components/Cookies.vue';
import LoginForm from '@/components/LoginForm.vue';

export default {
  name: 'Login',
  components: {
    Cookies,
    LoginForm,
  },
};
</script>
