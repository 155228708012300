<template>
  <div>
    <div class="split left sslr-bg-gradient">
      <div class="centered">
        <img
          class="responsive-img"
          src="../assets/logosermes_large.png"
          alt="Lenguage de signos"
          style="max-height: 400px; max-width: 100%;">
      </div>
    </div>
    <div class="split right">
      <v-alert v-if="error"
        type="error"
        transition="scale-transition">
        Correo no válido
      </v-alert>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md8>
            <v-form>
              <v-text-field
                underlined
                v-model="email"
                prepend-icon="mdi-account"
                name="email"
                label="Email"
                type="email"
                required></v-text-field>
            </v-form>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!userExist"
              class="reset-password"
              outlined
              block
              rounded
              large
              color="success"
              :disabled="!email"
              @click="checkEmailExists">
                Enviar Instrucciones
            </v-btn>
            <br/>
            <v-alert v-if="userExist"
              type="success"
              transition="scale-transition">
              Se le ha enviado un correo con las instrucciones para restablecer su contraseña
            </v-alert>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<script>
import { userServices } from '@/services/userServices';

export default {
  name: 'ForgotPassword',
  data() {
    return {
      email: '',
      error: false,
      userExist: false,
    };
  },
  methods: {
    checkEmailExists() {
      const data = { email: this.email };
      userServices.resetPassword(data)
        .then(() => {
          this.userExist = true;
          setTimeout(() => {
            this.userExist = false;
            this.$router.push('/login');
          }, 10000);
        })
        .catch(() => {
          this.error = true;
          setTimeout(() => {
            this.error = false;
          }, 5000);
        });
    },
  },
};
</script>

<style scoped>
.split {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
}
.left {
  left: 0;
}
.right {
  right: 0;
  background-color:white;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
</style>
