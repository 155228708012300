import axios from 'axios';
import Cookies from 'js-cookie';

export const coreRest = {
  generateRestUrl(path) {
    return `${process.env.VUE_APP_BASE_URL}${path}`;
  },
  async get(url, header, params) {
    // eslint-disable-next-line
    url = this.generateRestUrl(url);
    return axios.get(url, { headers: header, params });
  },
  async downloadBlob(url, data, header) {
    return axios.post(url, data, { headers: header, responseType: 'blob' });
  },
  async post(url, data, header) {
    // eslint-disable-next-line
    url = this.generateRestUrl(url);
    return axios.post(url, data, { headers: header });
  },
  async put(url, data, header) {
    // eslint-disable-next-line
    url = this.generateRestUrl(url);
    return axios.put(url, data, { headers: header });
  },
  async delete(url, header) {
    // eslint-disable-next-line
    url = this.generateRestUrl(url);
    return axios.delete(url, { headers: header });
  },
  /**
   * Get set of headers for an API request
   * @param {object} extraItemsToPush Extra key-value dictionary to add to the result
   * @return {object} Returns a dictionary object with all the needed values to add to the header of the API request,
   * such as: Token, Content-type, etc.
   */
  getRequestHeader(extraItemsToPush = null) {
    const headers = {
      Authorization: `Bearer ${Cookies.get('access_token')}`,
      'Content-Type': 'application/json',
    };
    if (extraItemsToPush !== null) {
      Object.assign(headers, extraItemsToPush);
    }
    return headers;
  },
};
