<template>
  <div>
    <v-overlay :value="loading" z-index=1000>
      <scale-loader :loading="loading" :color="'rgb(101,123,219)'" :size="'45px'"></scale-loader>
    </v-overlay>
    <div class="split left sslr-bg-gradient">
      <div class="centered">
        <img
          class="responsive-img"
          width="400"
          src="../assets/logosermes_large.png"
          alt="logo sermes">
      </div>
    </div>
    <div class="split right">
      <v-alert
        v-if="error"
        type="error"
        transition="scale-transition">
        {{ errorInfo }}
      </v-alert>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md8>
            <v-form>
              <v-text-field
                underlined
                v-model="email"
                prepend-icon="mdi-account"
                name="email"
                label="Email"
                type="email"
              ></v-text-field>
                <v-text-field
                id="change-password-oldpass-field"
                v-model="oldPassword"
                label="Antigua contraseña"
                type="password"
                prepend-icon="mdi-lock"
                placeholder="Old password"
                required
              ></v-text-field>
                <v-text-field
                id="change-password-newpass-field"
                v-model="newPassword"
                label="Nueva contraseña"
                type="password"
                prepend-icon="mdi-lock"
                :rules="[passwordRules]"
                placeholder="Password"
                required
              ></v-text-field>
              <v-text-field
                id="change-password-repeatpass-field"
                v-model="repeatedPassword"
                label="Repetir nueva contraseña"
                type="password"
                :rules="[passwordConfirmationRule]"
                prepend-icon="mdi-lock"
                placeholder="Password"
                required
              ></v-text-field>
            </v-form>
            <div>
              <p>
                <strong>Contraseña debe contener</strong>
              </p>
              <ul class="no-bullets">
                <li>
                  <v-icon @change="colorLenRules" :color="colorLength">mdi-check-bold</v-icon> {{ lengthRule }}
                </li>
                <li>
                  <v-icon @change="colorUpperRules" :color="colorUpper">mdi-check-bold</v-icon>{{ upperRule }}
                </li>
                <li>
                  <v-icon @change="colorLowerRules" :color="colorLower">mdi-check-bold</v-icon>{{ lowerRule }}
                </li>
                <li>
                  <v-icon @change="colorNumRules" :color="colorNumber">mdi-check-bold</v-icon>{{ numberRule }}
                </li>
                <li>
                <v-icon @change="colorCharRules" :color="colorCharacter">mdi-check-bold</v-icon>{{ characterRule }}
                </li>
              </ul>
            </div>
            <v-spacer></v-spacer>
            <v-btn
              class="mt-5"
              outlined
              large
              rounded
              @click="saveNewPassword">
              Guardar nueva contraseña
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>
<script>
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';
import { PasswordChecker } from '@/utils/passwordUtilities';
import { userServices } from '@/services/userServices';

export default {
  name: 'UpdateExpiredPasswordView',
  data() {
    return {
      PasswordChecker,
      loading: false,
      email: '',
      oldPassword: '',
      newPassword: '',
      repeatedPassword: '',
      error: '',
      errorInfo: '',
      success: '',
      lengthRule: 'Al menos 8 caracteres.',
      upperRule: 'Al menos 1 letra mayúscula (A..Z)',
      lowerRule: 'Al menos 1 letra minúscula (a..z)',
      numberRule: 'Al menos 1 número (0...9)',
      characterRule: 'Al menos 1 caracter especial (/..-)',
      colorLength: 'grey',
      colorUpper: 'grey',
      colorLower: 'grey',
      colorNumber: 'grey',
      colorCharacter: 'grey',
    };
  },
  components: {
    ScaleLoader,
  },
  computed: {
    passwordConfirmationRule() {
      return () => (this.newPassword === this.repeatedPassword) || 'Contraseña debe coincidir';
    },
    passwordRules() {
      return this.passwordStrong();
    },
    colorLenRules() {
      return this.passwordLen();
    },
    colorUpperRules() {
      return this.passwordUpper();
    },
    colorLowerRules() {
      return this.passwordLower();
    },
    colorNumRules() {
      return this.passwordNum();
    },
    colorCharRules() {
      return this.passwordChar();
    },
  },
  methods: {
    passwordStrong() {
      return PasswordChecker.passwordStrong(this.newPassword);
    },
    passwordLen() {
      const result = PasswordChecker.passwordLength(this.newPassword);
      this.colorLength = result ? 'green' : '';
      return result;
    },
    passwordUpper() {
      const result = PasswordChecker.passwordUpper(this.newPassword);
      this.colorUpper = result ? 'green' : '';
      return result;
    },
    passwordLower() {
      const result = PasswordChecker.passwordLower(this.newPassword);
      this.colorLower = result ? 'green' : '';
      return result;
    },
    passwordNum() {
      const result = PasswordChecker.passwordNum(this.newPassword);
      this.colorNumber = result ? 'green' : '';
      return result;
    },
    passwordChar() {
      const result = PasswordChecker.passwordChar(this.newPassword);
      this.colorCharacter = result ? 'green' : '';
      return result;
    },
    saveNewPassword() {
      this.loading = true;
      const data = {
        email: this.email,
        new_password: this.newPassword,
        old_password: this.oldPassword,
      };
      userServices.updateExpiredPassword(data)
        .then(() => {
          this.success = true;
          this.loading = false;
          setTimeout(() => {
            this.success = false;
            this.$router.push('/login');
          }, 2000);
        })
        .catch((error) => {
          this.loading = false;
          const errorCode = error.response.data.code;
          if (errorCode === 'password_not_valid') {
            this.errorInfo = 'Contraseña no cumple con los requisitos';
          } else if (errorCode === 'authentication_failed') {
            this.errorInfo = 'Email o contraseña incorrecta';
          } else {
            this.errorInfo = 'No válido';
          }
          this.error = true;
          setTimeout(() => {
            this.error = false;
          }, 2000);
        });
    },
  },
};
</script>

<style scoped>
.split {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
}
.left {
  left: 0;
}
.right {
  right: 0;
  background-color:white;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
</style>
