<template>
  <v-dialog v-model="dialog" max-width="800">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <span>¿Tienes dudas en cómo se realiza esta palabra?</span>
          <v-btn text color="black" class="">
            <v-icon class="">mdi-help-circle</v-icon>
          </v-btn>
        </div>
      </template>
      <v-card>
        <v-card-title class="sslr-bg-primary"><span class="white-text font-size-24">Ayuda</span></v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          <p class="font-size-20">
            <strong>Palabra</strong>: <span>{{ word }}</span>
          </p>
          <p>
            En caso de tener muchas dudas con la palabra seleccionada, no realice la grabación de la misma.
          </p>
          <p>Vídeo ejemplo:</p>
          <div class="text-center" v-if="!errorMessage">
            <v-progress-circular v-if="loading" indeterminate color="primary"
              model-value="20" :size="60"></v-progress-circular>
            <video width="460" controls muted id="video-help" v-if="videoHelp">
              <source :src="videoHelp" type="video/mp4">
              Your browser does not support the video tag.
              </video>
          </div>
          <p v-else>{{ errorMessage }}</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" outlined rounded @click="dialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import { wordServices } from '@/services/wordServices';

export default {
  name: 'RecordVideoDoubt',
  data() {
    return {
      dialog: false,
      loading: false,
      errorMessage: null,
      videoHelp: '',
    };
  },
  props: {
    word: String,
    word_id: Number,
  },
  async created() {
    this.getVideoHelp();
  },
  computed: {
    // vid() {
    //   const blob = new Blob([this.videoHelp]);
    //   return URL.createObjectURL(blob);
    // },
  },
  methods: {
    getVideoHelp() {
      this.loading = true;
      wordServices.getWordHelpVideo(this.word_id)
        .then((response) => {
          console.log(response);
          this.videoHelp = response.data.url;
          this.loading = false;
        })
        .catch(() => {
          this.errorMessage = 'Ha ocurrido un error obteniendo el vídeo';
          this.loading = false;
        });
    },
  },
};
</script>
