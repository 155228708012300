<template>
  <user-profile/>
</template>
<script>
import UserProfile from '../components/UserProfile.vue';

export default {
  name: 'Profile',
  components: {
    UserProfile,
  },
};
</script>
