<template>
  <div>
    <div class="split left sslr-bg-gradient pl-4 pr-4">
      <v-alert
        v-if="registerAlert"
        type="error"
        transition="scale-transition">
        Error, ya existe un usuario registrado con el email introducido
      </v-alert>
      <v-alert
        v-if="registerAlertOk"
        type="success"
        transition="scale-transition">
        Registrado correctamente. Por favor, revisa la bandeja de entrada de tu correo para activar la cuenta
      </v-alert>
      <div class="centered">
        <img
          class="responsive-img"
          width="250"
          src="../assets/logosermes_large.png"
          alt="logo sermes">
      </div>
    </div>
    <div class="split right">
      <v-container fluid fill-height>
        <v-row>
          <v-col class="text-center mb-5 mt-4 sslr-color-primary-blue">
            <h1 style="font-weight: 400;">Regístrate</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="10" offset-sm="1" md="8" offset-md="2" xl="7" offset-xl="3">
            <v-form>
              <v-text-field
                underlined
                v-model="email"
                prepend-icon="mdi-account"
                name="email"
                label="Email"
                type="email"
                autocomplete="off"></v-text-field>
              <v-text-field
                underlined
                v-model="repeatedEmail"
                prepend-icon="mdi-account"
                name="email"
                label="Repetir email"
                :rules="[emailConfirmationRule]"
                type="email"
                autocomplete="off"></v-text-field>
              <v-text-field
                underlined
                id="newpass-field"
                prepend-icon="mdi-lock"
                v-model="password"
                label="Contraseña"
                type="password"
                :rules="[passwordRules]"
                placeholder="Password"
                required
                autocomplete="off"></v-text-field>
              <v-text-field
                underlined
                id="repeatpass-field"
                prepend-icon="mdi-lock"
                v-model="repeatedPassword"
                label="Repetir Contraseña"
                type="password"
                :rules="[passwordConfirmationRule]"
                placeholder="Password"
                required
                autocomplete="off"></v-text-field>
              <div>
                <p>
                  <strong>Contraseña debe contener</strong>
                </p>
                <ul class="no-bullets">
                  <li>
                    <v-icon @change="colorLenRules" :color="colorLength">mdi-check-bold</v-icon> {{ lengthRule }}
                  </li>
                  <li>
                    <v-icon @change="colorUpperRules" :color="colorUpper">mdi-check-bold</v-icon>{{ upperRule }}
                  </li>
                  <li>
                    <v-icon @change="colorLowerRules" :color="colorLower">mdi-check-bold</v-icon>{{ lowerRule }}
                  </li>
                  <li>
                    <v-icon @change="colorNumRules" :color="colorNumber">mdi-check-bold</v-icon>{{ numberRule }}
                  </li>
                  <li>
                  <v-icon @change="colorCharRules" :color="colorCharacter">mdi-check-bold</v-icon>{{ characterRule }}
                  </li>
                </ul>
              </div>
              <div class="mt-5">
                <p><strong>Conocimiento Lengua de Signo</strong></p>
              </div>
              <v-radio-group v-model="slLevelSelected">
                <v-radio
                  v-for="slOption in constants.slKnowledgeOptions"
                  :key="slOption.text"
                  :label="slOption.text"
                  :value="slOption.value">
                </v-radio>
              </v-radio-group>
              <div class="">
                <p><strong>Mano</strong></p>
              </div>
              <v-radio-group v-model="handSelected">
                <v-radio
                  v-for="handOption in constants.handOptions"
                  :key="handOption.text"
                  :label="handOption.text"
                  :value="handOption.value">
                </v-radio>
              </v-radio-group>
              <div>
                <p><strong>Audición</strong></p>
              </div>
              <v-radio-group v-model="auditStateSelected">
                <v-radio
                  v-for="auditState in constants.auditStates"
                  :key="auditState.text"
                  :label="auditState.text"
                  :value="auditState.value">
                </v-radio>
              </v-radio-group>
              <div>
                <p><strong>Género</strong></p>
              </div>
              <v-radio-group v-model="genderSelected">
                <v-radio
                  v-for="genderOption in constants.genderOptions"
                  :key="genderOption.text"
                  :label="genderOption.text"
                  :value="genderOption.value">
                </v-radio>
              </v-radio-group>
              <div>
                <p>
                  <strong>Tonalidad de piel (fototipo)</strong>
                  <br>
                  Clasificar tonalidad de piel según la escala Fitzpatrick
                  <a href="https://es.wikipedia.org/wiki/Fototipo" target="_blank">(más información)</a>
                </p>
              </div>
              <v-radio-group v-model="fototypeSelected">
                <v-radio
                  v-for="fototypeOption in constants.fototypeOptions"
                  :key="fototypeOption.text"
                  :label="fototypeOption.text"
                  :value="fototypeOption.value">
                </v-radio>
              </v-radio-group>
              <v-checkbox v-model="termsOfService">
                <template v-slot:label>
                  <div>
                    Acepto los
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a
                          target="_blank"
                          :href="legalAdvice"
                          @click.stop
                          v-on="on">
                          términos y condiciones
                        </a>
                      </template>
                      Abrir en una nueva ventana
                    </v-tooltip>
                  .
                  </div>
                </template>
              </v-checkbox>
            </v-form>
            <v-spacer></v-spacer>
            <div class="text-center mt-5 mb-5">
              <v-progress-circular
              v-if="loading" indeterminate color="primary"
              model-value="20" :size="60"></v-progress-circular>
              <v-btn
                v-if="sendButton"
                class="ml-5"
                color="success"
                outlined
                rounded
                @click="signup()"
                :disabled="formValid">
                Registrarse
              </v-btn>
            </div>
            <br/>
            <div class="text-center mb-5">
                ¿Ya tienes una cuenta? <a href="/login">Logueate</a>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import { PasswordChecker } from '@/utils/passwordUtilities';
import { userServices } from '@/services/userServices';
import { constants } from '@/utils/constants';

export default {
  name: 'Register',
  data() {
    return {
      constants,
      PasswordChecker,
      loading: false,
      sendButton: true,
      registerAlertOk: false,
      registerAlert: false,
      email: '',
      repeatedEmail: '',
      password: '',
      repeatedPassword: '',
      error: false,
      auditStateSelected: '',
      handSelected: '',
      genderSelected: '',
      fototypeSelected: '',
      slLevelSelected: '',
      lengthRule: 'Al menos 8 caracteres.',
      upperRule: 'Al menos 1 letra mayúscula (A..Z)',
      lowerRule: 'Al menos 1 letra minúscula (a..z)',
      numberRule: 'Al menos 1 número (0...9)',
      characterRule: 'Al menos 1 caracter especial (/..-)',
      colorLength: 'grey',
      colorUpper: 'grey',
      colorLower: 'grey',
      colorNumber: 'grey',
      colorCharacter: 'grey',
      termsOfService: false,
    };
  },
  computed: {
    legalAdvice() {
      return 'https://storage.googleapis.com/sermesai-spaslr-static-admin/documentation/politica_privacidad.pdf';
    },
    passwordConfirmationRule() {
      return () => (this.password === this.repeatedPassword) || 'Contraseña debe coincidir';
    },
    emailConfirmationRule() {
      return () => (this.email === this.repeatedEmail) || 'Email debe coincidir';
    },
    passwordRules() {
      return this.passwordStrong();
    },
    colorLenRules() {
      return this.passwordLen();
    },
    colorUpperRules() {
      return this.passwordUpper();
    },
    colorLowerRules() {
      return this.passwordLower();
    },
    colorNumRules() {
      return this.passwordNum();
    },
    colorCharRules() {
      return this.passwordChar();
    },
    formValid() {
      const validPassword = this.passwordStrong();
      return !((validPassword)
      && (this.password === this.repeatedPassword)
      && (this.password !== '' && this.repeatedPassword !== '')
      && (this.email !== '')
      && (this.auditStateSelected !== '')
      && (this.handSelected !== '')
      && (this.genderSelected !== '')
      && (this.fototypeSelected !== '')
      && (this.slLevelSelected !== '')
      && this.colorLenRules
      && this.colorUpperRules
      && this.colorLowerRules
      && this.colorNumRules
      && this.colorCharRules
      && this.termsOfService);
    },
  },
  methods: {
    passwordStrong() {
      return PasswordChecker.passwordStrong(this.password);
    },
    passwordLen() {
      const result = PasswordChecker.passwordLength(this.password);
      this.colorLength = result ? 'green' : '';
      return result;
    },
    passwordUpper() {
      const result = PasswordChecker.passwordUpper(this.password);
      this.colorUpper = result ? 'green' : '';
      return result;
    },
    passwordLower() {
      const result = PasswordChecker.passwordLower(this.password);
      this.colorLower = result ? 'green' : '';
      return result;
    },
    passwordNum() {
      const result = PasswordChecker.passwordNum(this.password);
      this.colorNumber = result ? 'green' : '';
      return result;
    },
    passwordChar() {
      const result = PasswordChecker.passwordChar(this.password);
      this.colorCharacter = result ? 'green' : '';
      return result;
    },
    signup() {
      this.loading = true;
      this.sendButton = false;
      const data = {
        hand: this.handSelected,
        audition_state: this.auditStateSelected,
        gender: this.genderSelected,
        fototype_skin: this.fototypeSelected,
        signal_language_knowledge: this.slLevelSelected,
        email: this.email,
        password: this.password,
      };
      userServices.signup(data)
        .then(() => {
          this.loading = false;
          this.registerAlertOk = true;
          setTimeout(() => {
            this.$router.push('/login');
          }, 5000);
        })
        .catch(() => {
          this.loading = false;
          this.registerAlert = true;
          this.sendButton = true;
          setTimeout(() => {
            this.registerAlert = false;
          }, 10000);
        });
    },
  },
};
</script>
<style scoped>
  .split {
    height: 100%;
    width: 50%;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    padding-top: 20px;
  }
  .left {
    left: 0;
  }
  .right {
    right: 0;
    background-color:white;
  }
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
</style>
